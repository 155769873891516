import React, { useState, useContext, useEffect } from "react";
import "../../../App.css";
import { useAxiosSWR } from "root/axiosInstance";
import { AD_CAMPAIGN } from "../../../helpers/constants";
import { BiSolidNews } from "react-icons/bi";
import { ImFire } from "react-icons/im";
import { MdSportsCricket } from "react-icons/md";
import AppContext, { URL_TYPES } from "src/context";
import GoogleAdUnit from "../../../components/common/GoogleAdUnit";
import { getMobileDetect, SlotProps } from "../../../constants";
const LiveMatchesCarousel = React.lazy(() => import("./common/liveMatchesCarousel"));
const LatestNews = React.lazy(() => import("./common/LatestNews"));
const TopRankings = React.lazy(() => import("./common/topRanking/TopRanking"));
const LegendaryMomentum = React.lazy(() => import("./common/LegendaryMomentum"));
const FollowUs = React.lazy(() => import("../../common/FollowUs"));
const Series = React.lazy(() => import("./common/Series"));
const ExclusivelyVideos = React.lazy(() => import("./common/ExclusivelyVideos"));
const Facts = React.lazy(() => import("./common/Facts"));
const PointTable = React.lazy(() => import("./common/PointTable"));
const UserChoice = React.lazy(() => import("./common/UserChoice"));
const AdSquare = React.lazy(() => import("../../common/AdSquare"));
const SEOMeta = React.lazy(() => import("./common/SEOMeta"));
const WeeklyRecap = React.lazy(() => import("../../common/WeeklyRecap"));
const HomeNewsContainer = React.lazy(() => import("../../common/HomeNewsContainer"));
const FantasyNews = React.lazy(() => import("../../common/FantasyNews"));
const HomeShortsContainer = React.lazy(() => import("../shorts/HomeShortsContainer"));

function Home() {
    const { data, isLoading, error: isError } = useAxiosSWR("/v1/news/home/news");
    const { data: fantasyData, isLoading: fantasyLoading, error: fantasyErr } = useAxiosSWR("/v1/news/home/fantasy?per_page=10");
    const { data: homeNews, isLoading: isHomeNewsLoading, error: isHomeNewsError } = useAxiosSWR("/v1/news/home/news?per_page=4");
    const { data: seriesNewsData, isLoading: isSeriesNewsLoading, error: isSeriesNewsError } = useAxiosSWR("/v1/news/home/seriesNews");
    const { data: categoryNewsData, isLoading: isCategoryNewsLoading, error: isCategoryNewsError } = useAxiosSWR("/v1/news/home/categoryNews");
    const { data: topStoriesData, isLoading: isTopStoriesLoading, error: isTopStoriesError } = useAxiosSWR("/v1/news/home/top_story?per_page=10");
    const queryParams = new URLSearchParams(location.search);
    const showShorts = queryParams.get('showShorts') === "true";

    const appContext = useContext(AppContext);
    useEffect(() => {
        const urlInfoObj = {
            type: URL_TYPES.HOME,
            path: "/",
            reqState: null,
        };
        appContext.updateUrlInfo(urlInfoObj);
    }, []);

    const [activeTab, setActiveTab] = useState('Cricket News');

    const NewsTabs = ({ activeTab, setActiveTab }) => {
        const tabs = [
            { name: 'Cricket News', icon: <BiSolidNews className='mr-[5px] md:mr-2' /> },
            { name: 'Top Stories', icon: <ImFire className='mr-[5px] md:mr-2' /> },
            { name: 'Fantasy', icon: <MdSportsCricket className='mr-[5px] md:mr-2' /> }
        ];
        return (
            <div className='bg-white border rounded-[10px] md:rounded-2xl mb-4 md:p-3 p-[6px] md:mx-0 mx-[6px]'>
                <div className='grid grid-cols-3 text-xs md:text-base'>
                    {tabs.map(({ name, icon }, index) => (
                        <p
                            key={name+"_"+index}
                            onClick={() => setActiveTab(name)}
                            className={`cursor-pointer justify-center py-[11px] rounded-md flex items-center ${activeTab === name
                                ? `bg-[#3A32D1] text-white md:text-lg text-xs font-medium ${index === 0
                                    ? 'md:rounded-l-xl'
                                    : index === tabs.length - 1
                                        ? 'md:rounded-r-xl'
                                        : ''
                                }`
                                : 'bg-white md:text-lg text-xs font-medium'
                                }`}
                        >
                            {icon}
                            {name}
                        </p>
                    ))}
                </div>
            </div>
        );
    };

    const NewsContent = ({ activeTab }) => {
        return (
            <div>
                {activeTab === 'Cricket News' && (
                    <div key="CricketNews" className="mx-[6px] md:mx-0">
                        <React.Suspense fallback={<div className="shimmer white h-screen border rounded-2xl"></div>}>
                            <HomeNewsContainer
                                data={data?.data}
                                title={"Cricket News"}
                                isLoading={isLoading}
                                isError={isError}
                                LinkTo={"/cricket-news"}
                                type="news"
                            />
                        </React.Suspense>
                    </div>
                )}
                {activeTab === 'Top Stories' && (
                    <React.Suspense key="topStories">
                        <FantasyNews
                            data={topStoriesData?.data}
                            title="Top Stories"
                            isLoading={isTopStoriesLoading}
                            isError={isTopStoriesError}
                            LinkTo="/cricket-top-story"
                        />
                    </React.Suspense>
                )}
                {activeTab === 'Fantasy' && (
                    <React.Suspense key="fantasy">
                        <FantasyNews
                            data={fantasyData?.data}
                            title="Fantasy Cricket"
                            isLoading={fantasyLoading}
                            isError={fantasyErr}
                            LinkTo="/fantasy"
                        />
                    </React.Suspense>
                )}
                {seriesNewsData?.map((object, index) => (
                    <>
                        {object?.news?.data.length > 0 &&
                            <div className="mt-5 mx-[6px] md:mx-0" key={index + "_" + object?.date}>
                                <React.Suspense>
                                    <HomeNewsContainer
                                        data={object?.news?.data}
                                        title={object?.title}
                                        subTitle={object?.subtitle}
                                        isLoading={isSeriesNewsLoading}
                                        isError={isSeriesNewsError}
                                        LinkTo={`/series/${object.slug}/schedule`}
                                    />
                                </React.Suspense>
                            </div>}
                    </>
                ))}
                {categoryNewsData?.map((object, index) => (
                    <>
                        {object?.news?.data.length > 0 &&
                            <div className="mt-5 mx-[6px] md:mx-0" key={`${object.slug}_${index}`}>
                                <React.Suspense>
                                    <HomeNewsContainer
                                        data={object?.news?.data}
                                        title={object?.title}
                                        subTitle={object?.subtitle}
                                        isLoading={isCategoryNewsLoading}
                                        isError={isCategoryNewsError}
                                        LinkTo={`/category/${object.slug}`}
                                    />
                                </React.Suspense>
                            </div>}
                    </>
                ))}
            </div>
        );
    };

    const md = getMobileDetect();
    const isMobile = Boolean(md.mobile())
    const isNotMobile = !isMobile;

    return (
        <div className="outlet-container home min-h-screen">
            <React.Suspense>
                <SEOMeta slug="home" />
            </React.Suspense>
            <h1 className="hidden">CricketGully</h1>
            <div className="bg-[#2F27BE]">
                <div className="max-w-[1320px] xl:mx-auto">
                    <React.Suspense fallback={<div className="shimmer blue h-[228px] sm:h-[236px]"></div>}>
                        <LiveMatchesCarousel />
                    </React.Suspense>
                </div>
            </div>
            <div className="max-w-[1320px] xl:mx-auto md:mb-28 mb-2">
                <div className="hideScrollbar flex items-center justify-center mx-[6px] overflow-hidden">
                    {isMobile ? <GoogleAdUnit insCustomClass='mt-2' slotProps={SlotProps.Home_Page_Responsive} /> :
                        <GoogleAdUnit insCustomClass='mt-2' slotProps={SlotProps.Desktop_Above_Article} />
                    }
                </div>

                <div className="md:bg-white mt-4 md:p-2 rounded-xl md:border overflow-x-auto text-nowrap user-choice-wrapper hideScrollbar">
                    <React.Suspense fallback={<div className="shimmer md:white h-[60px] sm:h-[93px]"></div>}>
                        <UserChoice />
                    </React.Suspense>
                </div>
                <div className="grid lg:grid-cols-4 grid-cols-1 gap-4">
                    <div className="order-2 lg:order-1 mx-[6px] md:mx-0">
                        <React.Suspense fallback={<div className="shimmer white md:rounded-xl rounded-[15px] md:mt-5 mt-1 h-[370px] sm:h-[400px]"></div>}>
                            <div className="mb-5">
                                <LegendaryMomentum />
                            </div>
                        </React.Suspense>
                        <div>{/* py-4 px-3 */}{/* my-5 min-h-[250px] */}
                            <GoogleAdUnit slotProps={SlotProps.Desktop_Below_Match_Carousel} />
                        </div>
                        <React.Suspense>
                            <div className="mt-5">
                                <PointTable />
                            </div>
                        </React.Suspense>
                        <React.Suspense>
                            <LatestNews data={homeNews} isLoading={isHomeNewsLoading} isError={isHomeNewsError} />
                        </React.Suspense>
                        <React.Suspense>
                            <TopRankings />
                        </React.Suspense>
                        {isNotMobile ? <div className="flex justify-center sticky top-16">
                            <GoogleAdUnit insCustomClass='my-5' slotProps={SlotProps.Desktop_Asides_2} />
                        </div> : null}
                    </div>
                    <div className="lg:col-span-2 lg:mx-4 mt-5 order-1 lg:order-2">
                        {showShorts ?
                            <div className="mb-5 mx-[6px] md:mx-0">
                                <React.Suspense fallback={<div className="shimmer white h-screen border rounded-2xl"></div>}>
                                    <HomeShortsContainer />
                                </React.Suspense>
                            </div>
                            : null
                        }
                        <NewsTabs activeTab={activeTab} setActiveTab={setActiveTab} />
                        <NewsContent activeTab={activeTab} />
                        <div className="mt-5 mx-[6px] md:mx-0">
                            <React.Suspense fallback={<div className="shimmer white h-screen"></div>}>
                                <WeeklyRecap />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="order-3 mx-[6px] md-mx-0">
                        <React.Suspense fallback={<div className="shimmer white md:rounded-xl rounded-[15px] md:mt-5 mt-1 h-[370px] sm:h-[400px]"></div>}>
                            <div className="mb-5">
                                <Facts />
                            </div>
                        </React.Suspense>
                        <div className="">{/* py-4 px-3 */}{/* my-5 min-h-[250px] */}
                            <GoogleAdUnit insCustomClass='flex justify-center' slotProps={SlotProps.News_Details_Page_Square} />
                        </div>
                        <React.Suspense>
                            <div className="mt-5">
                                <ExclusivelyVideos />
                            </div>
                        </React.Suspense>
                        {isNotMobile ? <div> {/* my-5 flex justify-center */}
                            <GoogleAdUnit insCustomClass='my-5 flex justify-center' slotProps={SlotProps.Desktop_Asides_2} />
                        </div> : null}
                        <React.Suspense>
                            <Series />
                        </React.Suspense>
                        {isNotMobile ? <div className="sticky top-16"> {/* my-5 flex justify-center */}
                            <GoogleAdUnit insCustomClass='my-5 flex justify-center' slotProps={SlotProps.Desktop_Asides_2} />
                        </div> : null}
                        <div className="bg-white rounded-xl border p-4 md:px-4 px-[10px] mt-5 md:my-5 mb-2 md:hidden">
                            <React.Suspense><FollowUs isHomePage={true} /></React.Suspense>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
