import React, { useState } from "react";
import { ShimmerEffect } from "./ShimmerEffect";
import { getDateFormate } from "../pages/Schedule/ScheduleInstance/helper";
import { getAssetUrl, getSanitizedArray } from "../../helpers/general";

const open = getAssetUrl("OpenPointTable.svg");
const close = getAssetUrl("ClosePointTable.svg");

const COLUMNS = [
  {
    display: "P",
    key: "played",
  },
  {
    display: "W",
    key: "win",
  },
  {
    display: "L",
    key: "loss",
  },
  {
    display: "D",
    key: "draw",
  },
  {
    display: "PTS",
    key: "points",
  },
  {
    display: "NRR",
    key: "netrr",
  },
];

const CommonPointTable = ({ data, shimmerClassName }) => {
  const tableData = getSanitizedArray(data.groups);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const handalClick = (newTeam) => {
    setSelectedTeam((prevTeam) => {
      if (prevTeam !== newTeam) {
        return newTeam;
      }
      return null;
    });
  };
  if (data.groups.length < 1) {
    return <div className="text-xl font-semibold">No Point Table Found</div>
  }
  return (
    <div className="md:divide-y divide-gray-200">
      {tableData.map(({ name, table }) => (
        <div className="w-full text-xs md:text-sm md:my-0 my-4">
          {tableData.length > 1 &&
            <div className={`bg-white md:bg-none rounded-t-xl md:rounded-none md:border-none border border-[#E3E3E4]
             md:min-h-[18px] md:min-w-[180px] px-5 md:py-6 py-3 min-w-[50px] text-[24px] leading-[28px] text-[#000000] font-bold flex items-center`}>
              <span className="custom-border-left mr-3 md:mr-6 md:opacity-100 opacity-0"></span>
              <h1 className='text-lg md:text-2xl'>{name}</h1>
              <span className="custom-border-right ml-3 md:ml-6 md:opacity-100 opacity-0"></span>
            </div>}
          <div className="bg-[#EEF0F3] md:rounded-xl leading-5 md:font-bold font-medium">
            <div className="grid grid-cols-12 text-center items-center md:px-0 px-4">
              <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2.5 text-start col-span-5">
                Teams
              </div>
              {COLUMNS.map((column, index) => (
                <div
                  key={index}
                  className="lg:px-6 md:px-4 sm:px-2 px-1 py-3 col-span-1"
                >
                  {column.display}
                </div>
              ))}
            </div>
          </div>
          <div className="bg-white divide-y divide-gray-200 md:rounded-none rounded-b-xl">
            {table.map((team, index) => (
              <div key={index} className="md:px-0 px-4">
                <div
                  className="grid grid-cols-12 items-center cursor-pointer"
                  onClick={() => handalClick(team)}
                >
                  <div className="lg:px-4 md:px-3 sm:px-2 px-1 py-2 flex items-center col-span-5">
                    <ShimmerEffect
                      src={team.thumb_url}
                      alt="icon"
                      className="md:h-10 md:w-10 md:mr-3 mr-2 min-w-6 min-h-6"
                    />
                    <p className={`${shimmerClassName} min-h-6 min-w-28 md:mt-2 mt-1`}>{team.abbr}</p>
                  </div>
                  {COLUMNS.map((column, columnIndex) => (
                    <div
                      key={columnIndex}
                      className="col-span-1 lg:px-6 md:px-4 sm:px-2 px-0.5 py-2 text-center md:text-blue-800 text-[#000000] font-medium"
                    >
                      <p className={`${shimmerClassName}`}>
                        {team[column.key]}
                      </p>
                    </div>
                  ))}
                  <div className="col-span-1 flex justify-end md:mr-5">
                    {selectedTeam === team ? (
                      <div>
                        <img src={open} alt="open" />
                      </div>
                    ) : (
                      <div>
                        <img src={close} alt="close" />
                      </div>
                    )}
                  </div>
                </div>
                <div className={`team-scorecard ${selectedTeam === team ? "expanded" : "collapsed"
                  }`}>
                  <div>
                    <div>
                      <div className="mb-4 md:border border-[#DAE6F8] md:rounded-2xl">
                        <div className="grid md:grid-cols-6 grid-cols-7 rounded-t-2xl text-[10px] md:text-base md:px-6 px-4 py-3 md:bg-[#E8F1FF] bg-[#EEF0F3] md:text-[#000000] text-[#797979] md:border-0 border-[#DAE6F8]">
                          <div className="md:col-span-2 col-span-3 leading-5 md:font-bold font-semibold">
                            Opponent
                          </div>
                          <div className="col-span-1 leading-5 md:font-bold font-semibold md:block hidden">
                            Description
                          </div>
                          <div className="col-span-1 leading-5 md:font-bold font-semibold">
                            Date
                          </div>
                          <div className="md:col-span-2 col-span-2 leading-5 md:font-bold font-semibold">
                            Result
                          </div>
                        </div>
                        <div className="rounded-b-2xl md:bg-[#F9FBFF] bg-[#EEF0F3]">
                          {getSanitizedArray(team.matches).map(
                            (item, itemIndex) => (
                              <div
                                key={itemIndex}
                                className={`grid md:grid-cols-6 grid-cols-7 md:px-6 px-4 py-[7px] md:py-1 text-[10px] md:text-[14px] font-medium ${itemIndex === team.matches.length - 1 ? "" : "md:border-b"}`}
                              >
                                <div className="col-span-3 flex items-center h-max gap-1 md:hidden py-2">
                                  <div className={`rounded-full bg-[#c6c6c6] font-semibold text-white h-[14px] w-[14px] text-[8px] flex justify-center items-center`}></div>
                                  <div className="font-medium text-[10px] h-max leading-[14px] md:w-full max-w-[95px]">
                                    {item.opponent_name}
                                  </div>
                                </div>
                                <div className="md:col-span-2 py-2 md:py-1.5 md:block hidden">
                                  {item.opponent_name}
                                </div>
                                <div className="col-span-1 py-2 md:py-1.5 md:block hidden">
                                  {item.subtitle}
                                </div>
                                <div className="col-span-1 py-2 md:py-1.5">
                                  {getDateFormate(item.date_start, "dd mmm")}
                                </div>
                                <div
                                  className={`md:col-span-2 col-span-3 py-2 md:py-1.5 ${item.is_winning ? "text-[#3A32D1]" : "text-red-600"}`}
                                >
                                  {item.status_note}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CommonPointTable;
