import React, { useState } from 'react'
import { ShimmerEffect } from '../../common/ShimmerEffect';
import ShortsInstance from './ShortsInstance';
import { useNavigate } from 'react-router-dom';

const imgSrc = 'https://cricketgully-media.s3.ap-south-1.amazonaws.com/cg-spa-static-assets/IMG_20241210_132202.webp'
const imgsrc2 = 'https://cricketgully-media.s3.ap-south-1.amazonaws.com/cg-spa-static-assets/IMG_20241210_132153.webp'

const videoSrc = 'https://cricketgully-media.s3.ap-south-1.amazonaws.com/cg-spa-static-assets/kd_video_1.mp4'
const videoSrc2 = 'https://cricketgully-media.s3.ap-south-1.amazonaws.com/cg-spa-static-assets/kd_clean_bold.mp4'

export const shortsData = [
    {
        id: 1,
        media: [
            {
                type: 'image',
                src: imgSrc,
                title: 'Rohit Sharma',
                text: "Image 1",
            },
            {
                type: 'image',
                src: imgsrc2,
                title: 'Rohit Sharma',
                text: "Image 2",
            },
            {
                type: 'video',
                src: videoSrc,
                title: 'Vide 1',
            },
        ],
    },
    {
        id: 2,
        media: [
            {
                type: 'image',
                src: imgsrc2,
                text: "Sharma Ji is on the top of his league bywin",
            }
        ],
    },
    {
        id: 3,
        media: [
            {
                type: 'video',
                src: videoSrc2,
                title: 'Rohit Sharma',
                text: "Sharma Ji is on the top of his league bywin",
            },
            {
                type: 'image',
                src: imgsrc2,
                text: "Sharma Ji is on the top of his league bywin",
            }
        ],
    },
];

export default function Shorts() {

    const navigate = useNavigate();

    return (
        <>
            <div className='mt-2 md:m-0 mx-[6px] bg-white border md:rounded-2xl rounded-xl'>
                <div className='md:min-h-[18px] md:min-w-[180px] px-5 md:py-8 py-4 min-w-[50px] text-[24px] leading-[28px] text-[#000000] font-bold flex items-center'>
                    <span className="custom-border-left mr-3 md:mr-6"></span>
                    <h1 className='text-lg md:text-2xl'>Popular Stories</h1>
                    <span className="custom-border-right ml-3 md:ml-6"></span>
                </div>
                <div className="flex flex-wrap items-stretch md:px-3 md:mb-4">
                    {shortsData.map((item) => {
                        const firstMedia = item.media[0];
                        return (
                            <div
                                key={item.id}
                                className="block md:flex w-1/3 lg:w-1/4 p-[6px] md:p-[10px]"
                                onClick={() => navigate(`/shorts/${item.id}`)}
                            >
                                <div className="shorts-box relative rounded-xl md:rounded-[28px] overflow-hidden cursor-pointer">
                                    {firstMedia.type === 'image' ? (
                                        <ShimmerEffect
                                            src={firstMedia.src}
                                            alt='shorts-thumb'
                                            className='w-full h-full rounded-xl md:rounded-[28px] object-center'
                                        />
                                    ) : (
                                        <video
                                            src={firstMedia.src}
                                            className="w-full h-full rounded-xl md:rounded-[28px] object-cover"
                                        />
                                    )}
                                    <div className="absolute bottom-0 w-fit z-10 p-2 md:p-5">
                                        <p className="text-white text-[12px] md:text-2xl font-medium line-clamp-2">
                                            {firstMedia.text || firstMedia.title}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    )
}
