import React, { useEffect } from 'react'
import { formatDate } from '../../helpers/dates'
import { useAxiosSWR } from '../../../axiosInstance'
import { Link } from 'react-router-dom'
import { doScrollToTop, getNewsDetailsLinkPath, getSanitizedArray, resizedImageURL, getAssetUrl } from '../../helpers/general'
import { shimmerData } from '../../helpers/constants'
import { doHardReloadArticle, isEditorials } from '../../constants'
import CGLink from './CGLink'

const numberForDextop = {
    1: getAssetUrl("one.svg"),
    2: getAssetUrl("two.svg"),
    3: getAssetUrl("three.svg")
}
const WeeklyRecap = () => {
    const { data, isLoading, error: isError } = useAxiosSWR("/v1/news/home/weekly_recap?per_page=3");
    const weeklyRecapData = isLoading ? shimmerData : getSanitizedArray(data?.data);
    const shimmerClassName = weeklyRecapData?.[0]?.isShimmer ? "bg-gray-300 loading text-opacity-0" : ""

    if (isError) {
        return <div></div>;
    }

    return (
        <div className='bg-white md:rounded-2xl rounded-xl border'>
            <div className='text-center px-5'>
                <h2 className='md:py-6 py-4 text-base leading-4 md:leading-7 md:text-2xl border-b'>{isEditorials ? 'Cricket Gully Editorials' : 'CricketGully Weekly Recap'}</h2>
            </div>
            {isEditorials ?
                <div className='mx-4 md:mx-6'>
                    {weeklyRecapData.map((news, index) => (
                        <CGLink
                            doHardRedirect={doHardReloadArticle}
                            to={getNewsDetailsLinkPath(news.slug, news.id, news.type)}
                            key={index + "_" + news?.date}
                            onClick={doScrollToTop}
                        >
                            <div data-weeklyreacpId={news?.id} key={news?.id} className='grid grid-cols-10 gap-3 lg:gap-0 my-6'>
                                <div className='col-span-10 md:ml-2'>
                                    <div className='grid grid-cols-6 md:grid-cols-5'>
                                        <div className='col-span-1 md:col-span-1'>
                                            <img src={resizedImageURL(news?.image, "180x180")} alt="news" className={`${shimmerClassName} h-full w-[71px] md:w-full md:h-[90px] rounded-xl object-cover`} />
                                        </div>
                                        <div className='col-span-5 md:ml-5 ml-3 md:col-span-4'>
                                            <p className={`${shimmerClassName} news-title min-h-11 text-sm md:text-base leading-[22px]'`}>
                                                {news?.title}
                                            </p>
                                            <p className={`${shimmerClassName} text-xs md:text-sm leading-[16px] font-semibold text-[#6c6c6c96]`}>
                                                {formatDate(news?.date)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CGLink>
                    ))}
                </div>
                :
                <div className='mx-4 md:mx-6'>
                    {weeklyRecapData.map((news, index) => (
                        <CGLink
                            doHardRedirect={doHardReloadArticle}
                            to={getNewsDetailsLinkPath(news.slug, news.id, news.type)}
                            key={index + "_" + news?.date}
                            onClick={doScrollToTop}
                        >
                            <div data-weeklyreacpId={news?.id} key={news?.id} className='grid grid-cols-10 gap-3 lg:gap-0 my-6'>
                                <div className='col-span-1 my-auto mx-auto'>
                                    <img src={numberForDextop[index + 1]} alt={index} className={`md:h-[80px] h-[37px] w-[30px] md:w-[60px] my-auto ${shimmerClassName}`} />
                                </div>
                                <div className='col-span-9 md:ml-2'>
                                    <div className='grid grid-cols-6 md:grid-cols-5'>
                                        <div className='col-span-2 md:col-span-1'>
                                            <img src={resizedImageURL(news?.image, "180x180")} alt="news" className={`${shimmerClassName} h-full w-full md:h-[90px] rounded-xl object-cover`} />
                                        </div>
                                        <div className='col-span-4 md:ml-5 ml-3 md:col-span-4'>
                                            <p className={`${shimmerClassName} news-title min-h-11 text-sm md:text-base leading-[22px]'`}>
                                                {news?.title}
                                            </p>
                                            <p className={`${shimmerClassName} text-xs md:text-sm leading-[16px] font-semibold text-[#6c6c6c96]`}>
                                                {formatDate(news?.date)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CGLink>
                    ))}
                </div>
            }
            <div className='flex items-end justify-center h-2 w-full bg-gradient-to-t from-[#e2e2e2ab] to-transparent'>
            </div>
            <div className='text-[#3A32D1] text-center py-3 md:text-lg text-xs font-semibold'>
                <Link
                    key='btnSeeMoreNews2'
                    to={"/cricket-news"}
                    onClick={doScrollToTop}
                >
                    See More
                </Link>
            </div>
        </div>
    )
}

export default React.memo(WeeklyRecap);