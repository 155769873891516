import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getAssetUrl } from "../../helpers/general";
import "./navbar.css";
import { doScrollToTop } from "../../helpers/general";
import { useAxiosSWR } from "../../../axiosInstance";
import { IoIosArrowDown } from "react-icons/io";
import { TiArrowSortedUp } from "react-icons/ti";

const links = [
  { label: "Home", target: "/", route: "" },
  { label: "Schedule", target: "/schedule/live", route: "schedule" },
  { label: "Cricket News", target: "/cricket-news", route: "cricket-news", id: "cricket" },
  { label: "Fantasy", target: "/fantasy", route: "fantasy" },
  { label: "Series", target: "/series", route: "series" },
  { label: "Ranking", target: "/ranking", route: "ranking" },
  { label: "Points Table", target: "/points-table", route: "points-table" },
  { label: "Teams", target: "/teams", route: "teams" },
  { label: "More", isDropdown: true, icon: <IoIosArrowDown />, id: "category" }
];

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname.split("/")[1];
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [moreDropdownOpen, setMoreDropdownOpen] = useState(false);
  const cGullyLoginTokenFromStorage = localStorage.getItem("cGullyLoginToken");
  const { data: moreDropdownData, isLoading, error: isError } = useAxiosSWR("/v1/news/categories");
  const dropdownRef = useRef(null);

  const logo = getAssetUrl("logo.png");
  const livescore = getAssetUrl("navbar/livescore.svg");
  const pointtable = getAssetUrl("navbar/pointtable.svg");
  const teams = getAssetUrl("navbar/teams.svg");
  const ranking = getAssetUrl("navbar/ranking.svg");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMoreDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (menuIsOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [menuIsOpen]);
  const handleCloseMenu = () => {
    setMenuIsOpen(false);
  };
  const handleLoginClick = () => {
    if (cGullyLoginTokenFromStorage) {
      navigate("/user-profile");
    } else {
      navigate("/login");
    }
  };
  const handleMoreClick = () => {
    setMoreDropdownOpen(!moreDropdownOpen);
  };

  const DropdownMenu = ({ items }) => (
    <div className="relative" ref={dropdownRef}>
      <TiArrowSortedUp className="absolute text-white top-1.5 w-10 h-8 left-1/2 transform -translate-x-1/2" />
      <div className={`absolute top-6 -left-6 bg-white shadow-lg rounded-xl px-6 z-50 ${items.length >= 8 ? 'overflow-y-auto max-h-[420px] hideScrollbar' : ''}`}>
        <h3 className="text-base font-semibold text-center py-4">More</h3>
        <div className="border-b"></div>
        <div className="py-3">
          {items.map((item) => (
            <Link
              key={item.id}
              to={`/category/${item.category_slug}`}
              onClick={() => {
                setMoreDropdownOpen(false);
                doScrollToTop();
              }}
            >
              <div className="py-2 hover:text-[#3A32D1] hover:font-semibold">
                <p className="text-xs font-normal">{item.name}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <nav>
      <div className="bg-[#3A32D1] relative">
        <div
          className={`max-w-[1320px] xl:mx-auto flex justify-between items-center h-16`}
        >
          <div className="flex items-center md:w-auto w-full">
            <Link to="/" className="flex md:mx-0 mx-auto items-center" onClick={doScrollToTop}>
              <img className="h-8 w-auto" src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="flex space-x-4">
            {links.map((link, index) => (
              <div key={index} className="relative">
                <Link
                  to={link.target}
                  className={`relative text-white hover:text-gray-300 
                    after:absolute after:content-[''] 
                    after:-bottom-5 after:left-0 
                    after:w-full after:h-[5px] after:bg-white 
                    after:origin-left after:scale-x-0 
                    ${pathname.includes(link.id) || pathname === link.route ? "after:scale-x-100 after:transition-transform after:duration-300" : ""}
                    `}
                  onClick={link.isDropdown ? handleMoreClick : doScrollToTop}
                >
                  <div className="flex items-center gap-1">
                    {link.label}
                    {link.icon && <span>{link.icon}</span>}
                  </div>
                </Link>
                {link.isDropdown && moreDropdownOpen && !isLoading && !isError && (
                  <DropdownMenu items={moreDropdownData}>
                    {moreDropdownData && moreDropdownData.length > 0 && (
                      moreDropdownData?.map((item) => (
                        <Link key={item.id} to={`/category/${item.slug}`} onClick={() => setMoreDropdownOpen(false)}>
                          <div className="py-2">
                            <p>{item.title}</p>
                          </div>
                        </Link>
                      ))
                    )}
                  </DropdownMenu>
                )}
              </div>
            ))}
          </div>
          <div className="absolute md:static right-0 md:pr-4 pr-2 text-[12px] md:text-base" onClick={handleLoginClick}>
            <button className={`bg-white rounded-md p-1 text-[#3A32D1] font-semibold px-3 hover:text-gray-300 ${(pathname === "login" || pathname === "user-profile") ? "border-b-2 border-white pb-1" : ""
              }`}>{cGullyLoginTokenFromStorage ? "Profile" : "Login"}</button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
