import React, { useState, useEffect } from "react";
import { getAssetUrl } from "src/helpers/general";
import { formatDate, states } from "./constants";
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import { useAxiosSWR } from "root/axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import usePostMultipart from "../../../hooks/usePostMultipart";

const defaultImg = getAssetUrl("Ellipse_87.png");
const backbar = getAssetUrl("backbar.svg");
const aero = getAssetUrl("downAero.svg");
const icon = getAssetUrl("calendar_month.svg");
const profileIcon = getAssetUrl("profile-icon.svg");

const AUTO_CLOSE_DURATION = 15000;
const EditProfileForm = () => {
  const navigate = useNavigate();
  const cGullyLoginTokenFromStorage = localStorage.getItem("cGullyLoginToken");
  const url = cGullyLoginTokenFromStorage ? "/v1/user/profile" : null;
  const { data: profileData, isLoading: isLoadingProfile } = useAxiosSWR(url);
  const [doLogout, setDoLogout] = useState(false);
  const logoutReq = useAxiosSWR(doLogout ? "v1/user/logout" : null);
  const { data: logoutData, isLoading: isLoggingOut, error: logoutError } = logoutReq;
  const saveReqState = usePostMultipart();
  const { errorObj2: errorMessage } = saveReqState;

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage)
    }
  }, [errorMessage]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobile: "",
    date: "",
    state: "",
    profilePic: null,
  });

  useEffect(() => {
    if (logoutReq.data && logoutReq.data === "Logout successful") {
      localStorage.removeItem("cGullyLoginToken");
      localStorage.removeItem("cGullyLoginMode");
      navigate("/");
    }
  }, [logoutReq]);

  useEffect(() => {
    if (!cGullyLoginTokenFromStorage) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (profileData) {
      setFormData({
        fullName: profileData?.name || "",
        email: profileData?.email || "",
        date: profileData?.birthdate ? formatDate(profileData?.birthdate) : formatDate(new Date()),
        mobile: profileData?.mobile?.includes("DUMMY") ? "" : profileData?.mobile || "",
        state: profileData?.state || "",
        profilePic: profileData?.profilePic || null,
      });
    }
  }, [profileData]);

  useEffect(() => {
    const { fullName, email, mobile, date, state } = formData;
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const formValid = [fullName, email, mobile, date, state].every(Boolean) && isEmailValid;
    setIsFormValid(formValid);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "profilePic" && files.length > 0) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        profilePic: files[0],
      }));

      const reader = new FileReader();
      reader.onload = () => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          profilePicPreview: reader.result,
        }));
      };
      reader.readAsDataURL(files[0]);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    if (isFormValid) {
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("name", formData.fullName);
      formDataToSubmit.append("email", formData.email);
      formDataToSubmit.append("birthdate", formData.date);
      formDataToSubmit.append("mobile", formData.mobile);
      formDataToSubmit.append("state", formData.state);

      if (formData.profilePic && (formData.profilePic + "").startsWith("http") === false) {
        formDataToSubmit.append("file", formData.profilePic);
      }
      const apiUrl = "v1/user/updateProfile";
      try {
        const response = await saveReqState.makeRequest(apiUrl, formDataToSubmit);
        if (response && response.status === 'success') {
          toast.success(`Profile updated successfully`);
        }
      } catch (error) {
        console.log('catch error in handle submit', error);
        toast.error(error.message);
      }
    }
  };

  return (
    <div className="md:text-center">
      <ToastContainer autoClose={AUTO_CLOSE_DURATION} position="top-center" />
      <Header />
      <FormFields
        formData={formData}
        handleChange={handleChange}
        isFormValid={isFormValid}
        handleSubmit={handleSubmit}
        isLoggingOut={isLoggingOut}
        setDoLogout={setDoLogout}
        isLoadingProfile={isLoadingProfile}
        profileData={profileData}
      />
      <div className="hidden md:block md:py-4 md:text-[#777777] md:text-[14px]">
        Proceed to agree to
        <a href={"https://cricketgully.com/p/terms-of-use"} className="underline">
          {" "}
          Terms of Services{" "}
        </a>
        &amp;
        <a href={"https://cricketgully.com/p/privacy-policy"} className="underline">
          {" "}
          Privacy Policy{" "}
        </a>
      </div>
    </div>
  );
};

const Header = () => (
  <div className="justify-center my-[40px] hidden md:flex">
    <p className="w-[816px] py-[24px] px-[30px] rounded-[20px] border-[#E3E3E4] bg-[#FFFFFF] font-bold text-[24px]">
      Profile Settings
    </p>
  </div>
);

const FormFields = ({ formData, handleChange, isFormValid, handleSubmit, isLoggingOut, setDoLogout, isLoadingProfile, profileData }) => {
  console.log({ formData })
  const [date, setDate] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const navigate = useNavigate();

  const handleCalendarClick = (date) => {
    const newDate = new Date(date);
    const formattedDate = formatDate(newDate, false);
    handleChange({ target: { name: "date", value: formattedDate } });
    setDate(formattedDate);
    setShowCalendar(false);
  };

  return (
    <>
      <div className="sticky top-0 z-50 flex justify-center items-center w-full bg-[#3A32D1] md:hidden py-4">
        <img
          src={backbar}
          alt=""
          className="absolute left-2 pl-4"
          onClick={() => navigate("/")}
        />
        <h6 className="text-[#FFFFFF] text-[12px] text-center">
          Profile
        </h6>
      </div>
      <div className="flex justify-center my-[40px]">
        <div className="w-full md:w-[816px] md:bg-[#FFFFFF] shadow-lg px-[16px] md:px-[60px] md:grid md:grid-cols-8 md:gap-4 rounded-[32px]">
          <ProfileImageField
            handleChange={handleChange}
            previewImage={formData.profilePicPreview || formData.profilePic || (isLoadingProfile ? null : defaultImg)}
          />
          <InputField
            label="Full Name"
            name="fullName"
            value={formData.fullName}
            handleChange={handleChange}
          />
          <InputField
            label="Email ID"
            type="email"
            name="email"
            value={formData.email}
            handleChange={handleChange}
            disabled={formData.email !== ""}
          />
          <InputField
            label="Phone no"
            type="text" // Changed from "number" to "text"
            name="mobile"
            value={formData.mobile}
            handleChange={handleChange}
            prefix="+91"
            maxLength={10} // Set maxLength to 10
            pattern="\d*" // Optional: Ensures only digits are entered
            disabled={!profileData?.mobile?.includes("DUMMY")}
          />
          <BODCalendar
            label="Date of Birth"
            name="date"
            value={formData.date}
            handleCalendarClick={handleCalendarClick}
            icon={icon}
            showCalendar={showCalendar}
            date={date}
            setShowCalendar={setShowCalendar}
          />
          <InputField
            label="State"
            name="state"
            type="select"
            value={formData.state}
            handleChange={handleChange}
            options={states}
          />
          <SaveButton isLoadingProfile={isLoadingProfile} isFormValid={isFormValid} handleSubmit={handleSubmit} isLoggingOut={isLoggingOut} setDoLogout={setDoLogout} />
        </div>
      </div>
    </>
  );
};

const BODCalendar = ({
  handleCalendarClick,
  value,
  showCalendar,
  label,
  name,
  icon,
  setShowCalendar,
}) => {
  return (
    <div className="relative w-full md:col-span-4">
      <div>
        <span className="text-xs text-[#1C1B1F] opacity-40 md:absolute md:top-[-12px] md:left-0">
          {label}
        </span>
        <input
          type="text"
          className={`relative w-full pl-4 py-[15px] px-[16px] rounded-[12px] border outline-none focus:ring-none border-gray-300 md:mt-2 md:mb-4 mb-2`}
          name={name}
          value={value}
          onClick={() => {
            setShowCalendar(true);
          }}
          onChange={() => { }}
          placeholder={`Add your ${label.toLowerCase()}`}
          autoComplete="off"
        />
        <img
          src={icon}
          alt="calendar"
          className="absolute right-4 md:top-[45%] top-[60%] transform -translate-y-1/2 cursor-pointer"
          onClick={() => setShowCalendar(!showCalendar)}
        />
      </div>
      {showCalendar && (
        <div className="absolute bg-white right-0 z-40 top-16">
          <Calendar
            onChange={handleCalendarClick}
            value={new Date(value) || new Date()}
            next2Label={null}
            prev2Label={null}
            maxDate={new Date()}
          />
        </div>
      )}
    </div>
  );
};

// InputField Component
const InputField = ({
  label,
  type = "text",
  name,
  value,
  handleChange,
  prefix,
  options,
  disabled,
  ...rest // Capture additional props
}) => {
  const isSelectField = type === "select";

  return (
    <label className="relative w-full md:col-span-4">
      <span className="text-xs text-[#1C1B1F] opacity-40 md:absolute md:top-[-12px] md:left-0">
        {label}
      </span>
      <div className="relative">
        {prefix && (
          <span className="absolute md:top-6 top-4 left-4">{prefix}</span>
        )}
        {isSelectField ? (
          <select
            className="lg:min-w-[696px] md:min-w-[650px] w-full py-[15px] px-[16px] rounded-[12px] border outline-none focus:ring-none border-gray-300 md:mt-2 mb-4 appearance-none bg-no-repeat bg-right"
            name={name}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            style={{
              backgroundImage: `url(${aero})`,
              backgroundPosition: "right 16px center",
              backgroundSize: "16px",
              backgroundRepeat: "no-repeat",
            }}
            {...rest} // Spread additional props to <select>
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        ) : (
          <input
            type={type}
            className={`w-full ${prefix ? "pl-14" : "pl-4"
              } py-[15px] px-[16px] rounded-[12px] border outline-none focus:ring-none border-gray-300 md:mt-2 md:mb-4 mb-2`}
            name={name}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            placeholder={`Add your ${label.toLowerCase()}`}
            autoComplete="off"
            {...rest} // Spread additional props to <input>
          />
        )}
      </div>
    </label>
  );
};


const ProfileImageField = ({ handleChange, previewImage }) => {
  const loadFile = (event) => {
    const reader = new FileReader();
    reader.onload = () => {
      handleChange({ target: { name: "profilePic", files: [event.target.files[0]] } });
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <div className="flex justify-center w-full md:col-span-8 relative">
      <div className="relative">
        <img
          src={previewImage}
          alt="Profile"
          className="w-[100px] h-[100px] md:my-[60px] mb-[40px] md:w-[112px] md:h-[112px] rounded-full"
        />
        <label
          htmlFor="profilePic"
          className="absolute md:bottom-12 bottom-8 md:right-2 right-0 w-[35px] h-[35px] cursor-pointer"
        >
          <input
            type="file"
            accept="image/*"
            id="profilePic"
            onChange={loadFile}
            className="hidden"
          />
          <img id="output" />
          <img src={profileIcon} alt="Upload" />
        </label>
      </div>
    </div>
  );
};

const SaveButton = ({ isFormValid, handleSubmit, isLoggingOut, setDoLogout, isLoadingProfile }) => (
  <div className="flex justify-center w-full md:col-span-8 gap-8">
    <button
      type="button"
      className={`bg-[#3A32D1] w-full md:w-[441px] my-[20px] md:my-[60px] h-[54px] md:h-[62px] text-white px-[16px] rounded-[12px] shadow-lg ${isFormValid ? "" : "opacity-50 cursor-not-allowed"
        }`}
      disabled={!isFormValid}
      onClick={handleSubmit}
    >
      Save
    </button>
    <button
      disabled={isLoggingOut || isLoadingProfile}
      className={`bg-white border border-red-700 w-full md:w-[441px] my-[20px] md:my-[60px] h-[54px] md:h-[62px] px-[16px] rounded-[12px] shadow-lg`}
      onClick={() => setDoLogout(true)}>
      Log out
    </button>
  </div>
);

export default EditProfileForm;
