import React, { useCallback, useEffect, useState } from "react";
import OTPInputForm from "./Otp";
import MobileInputForm from "./Number";
import { SocialLoginForm } from "./SocialLogin";
import { useNavigate } from "react-router-dom";
import SEOMeta from "../Home/common/SEOMeta";
import { getAssetUrl } from "../../../helpers/general";

const background = getAssetUrl("background.png");
const logo = getAssetUrl("mobilelogo.png");
const vector29 = getAssetUrl("vector29.png");
const vector30 = getAssetUrl("vector30.png");
const backbar = getAssetUrl("backbar.svg");

const initialState = {
  currentFormID: "MOBILE_NUMBER",
  mobileNumber: "",
  reqestID: "",
};

const LoginMain = () => {
  const [state, setState] = useState(initialState);
  const cGullyLoginTokenFromStorage = localStorage.getItem("cGullyLoginToken");
  const navigate = useNavigate();
  const changeMobileNumber = useCallback(() => {
    setState((prev) => ({
      ...prev,
      currentFormID: "MOBILE_NUMBER",
    }));
  }, [setState]);

  const handleMobileFormSuccess = useCallback(
    (reqestID, phoneNumber) => {
      setState((prev) => ({
        ...prev,
        mobileNumber: phoneNumber,
        reqestID: reqestID || prev.reqestID,
        currentFormID: "OTP",
      }));
    },
    [setState]
  );
  useEffect(() => {
    if (cGullyLoginTokenFromStorage) {
      navigate("/user-profile");
    }
  }, []);
  return (
    <div className="relative min-h-screen bg-white sm:bg-transparent">
      <SEOMeta slug="login" />
      <div
        className="absolute -top-4 left-0 w-full h-1/2 bg-cover"
        style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
        }}
      ></div>
      <div className="sticky top-0 z-50 flex justify-center items-center w-full bg-[#3A32D1] md:hidden py-4">
        <img
          src={backbar}
          alt=""
          className="absolute left-2 pl-4"
          onClick={() => navigate("/")}
        />
        <h6 className="text-[#FFFFFF] text-[12px] text-center">
          Login/Register
        </h6>
      </div>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 w-full">
        <div className="flex flex-col items-center">
          <img
            src={logo}
            alt="logo"
            className="z-10 py-6 w-38 h-40 sm:hidden"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="bg-white rounded-t-3xl sm:rounded-3xl w-full sm:w-[400px] mx-auto p-4 sm:p-8">
          {/* <div className="text-center font-semibold mb-2 text-lg">
            Login With
          </div> */}
          <MobileInputForm
            handleSuccess={handleMobileFormSuccess}
            mobileNumber={state.mobileNumber}
          />
          {/* <div className="">
            {state.currentFormID === "MOBILE_NUMBER" ? (
            ) : (
              <OTPInputForm
                changeMobileNumber={changeMobileNumber}
                phoneNumber={state.mobileNumber}
                reqestId={state.reqestID}
              />
            )}
          </div>
          <div className="flex justify-center items-center my-7 sm:flex-row">
            <img
              src={vector30}
              alt="left"
              className="h-0.5 w-16 sm:h-0.5 sm:w-28 mt-1"
            />
            <p className="text-[#4F4F4F] sm:text-[14px] text-[12px] mx-2 sm:mx-4 text-nowrap">
              Or continue with
            </p>
            <img
              src={vector29}
              alt="right"
              className="h-0.5 w-16 sm:h-0.5 sm:w-28 mt-1"
            />
          </div> */}
            <SocialLoginForm />
          <div className="text-center sm:hidden text-[#777777] text-nowrap bg-white w-full text-[12px] pt-4">
            Proceed to agree to
            <a
              href={"https://cricketgully.com/p/terms-of-use"}
              className="underline"
            >
              {" "}
              Terms of Services{" "}
            </a>
            &amp;
            <a href={"https://cricketgully.com/p/privacy-policy"} className="underline">
              {" "}
              Privacy Policy{" "}
            </a>
          </div>
        </div>
      </div>
      <div className="sm:block absolute bottom-0 left-1/2 text-center hidden transform -translate-x-1/2 text-[#777777] text-[14px] py-4">
        Proceed to agree to
        <a href={"https://cricketgully.com/p/terms-of-use"} className="underline">
          {" "}
          Terms of Services{" "}
        </a>
        &amp;
        <a href={"https://cricketgully.com/p/privacy-policy"} className="underline">
          {" "}
          Privacy Policy{" "}
        </a>
      </div>
    </div>
  );
};

export default LoginMain;
