import React, { useEffect, useState, useContext } from "react";
import CalendarBar from "./Schedule/ScheduleInstance/common";
import { useAxiosSWR } from "root/axiosInstance";
import { getDateFormate } from "./Schedule/ScheduleInstance/helper";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { getAssetUrl, getNewsDetailsLinkPath } from "../../helpers/general";
import SEOMeta from "./Home/common/SEOMeta";
import AppContext, { URL_TYPES } from "src/context";
import GoogleAdUnit from "../common/GoogleAdUnit";
import { doHardReloadArticle, getMobileDetect, SlotProps } from "../../constants";
import CGLink from "../common/CGLink";
import ArticleCategoryIntroduction from "../ArticleCategoryIntroduction";

const viewIcon = getAssetUrl("view.svg");

// 2024-12-10T03:15:00.000Z
const convertDateToISD = (dateStr) => {
    try {
        const date = new Date(dateStr);
        if (isNaN(date.getTime())) return '';
        const options = {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        };
        return date.toLocaleTimeString('en-IN', options);

    } catch (error) {
        return '';
    }
}

const FantasyV2 = () => {
    const [dateRange, setDateRange] = useState({});
    const [selectedDate, setSelectedDate] = useState(null);
    const [uniqueDates, setUniqueDates] = useState([]);
    const appContext = useContext(AppContext);
    const { pathname: pathName } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const showArticleCategoryIntro = searchParams.get('showArticleCategoryIntro') === "true";

    useEffect(() => {
        const urlInfoObj = {
            type: URL_TYPES.FANTASY_CATEGORY,
            path: pathName,
            reqState: null,
        };
        appContext.updateUrlInfo(urlInfoObj);
    }, [pathName]);

    const getDateRange = (date, type = "next") => {
        let fromDate = new Date(date);
        const toDate = new Date(date);

        if (type === "previous") {
            fromDate.setDate(fromDate.getDate() - 10);
        } else if (type === "next") {
            toDate.setDate(toDate.getDate() + 10);
        }

        const formattedFromDate = getDateFormate(fromDate);
        const formattedToDate = getDateFormate(toDate);

        setTimeout(() => {
            setDateRange({ fromDate: formattedFromDate, toDate: formattedToDate });
        }, 500);
    };

    useEffect(() => {
        const today = new Date();
        getDateRange(today);
        setSelectedDate(getDateFormate(today));
    }, []);

    useEffect(() => {
        setSelectedDate(getDateFormate(dateRange?.fromDate));
    }, [dateRange]);

    const {
        data: fantasyData,
        error: isError,
        isLoading,
    } = useAxiosSWR(
        dateRange.toDate && dateRange.fromDate
            ? `/v1/fantasy/matches/${dateRange.fromDate}/${dateRange.toDate}`
            : null
    );

    useEffect(() => {
        if (fantasyData) {
            const dates = fantasyData.map(match => getDateFormate(new Date(match?.date_start)));
            const uniqDates = [...new Set(dates)];
            setUniqueDates(uniqDates);
        }
    }, [fantasyData]);

    const onDateClick = (dateStr) => {
        scrollSmooth(`matches_on_${dateStr}`);
        setSelectedDate(dateStr);
    };

    if (isError) {
        return <div></div>;
    }

    const filteredMatches = fantasyData?.filter(match => getDateFormate(new Date(match?.date_start)) === selectedDate) || [];

    const md = getMobileDetect();
    const isMobile = Boolean(md.mobile());

    return (
        <div>
            <SEOMeta slug="fantasy" />
            {showArticleCategoryIntro ? <ArticleCategoryIntroduction category_slug='fantasy' /> : null}
            <div className="md:bg-white md:border rounded-2xl my-4 mt-0 md:mt-4 md:mb-5 ">
                {showArticleCategoryIntro ? null :
                    <div className="hidden md:block">
                        <div className='md:min-h-[18px] md:min-w-[180px] px-5 md:py-7 py-4 min-w-[50px] text-[24px] leading-[28px] text-[#000000] font-bold flex items-center'>
                            <span className="custom-border-left mr-3 md:mr-6"></span>
                            <h1 className='text-2xl'>Fantasy Tips</h1>
                            <span className="custom-border-right ml-3 md:ml-6"></span>
                        </div>
                    </div>
                }
                <div className={`${showArticleCategoryIntro ? "md:pt-6" : ""} md:px-6`}>
                    <CalendarBar
                        getDateRange={getDateRange}
                        dateRange={dateRange}
                        pageName="fantasy"
                        isLoading={isLoading}
                        onDateClick={onDateClick}
                        uniqueDates={uniqueDates}
                    />
                </div>
            </div>
            {isMobile &&
                <div className="rounded-xl -x-auto hideScrollbar flex items-center justify-center">
                    <GoogleAdUnit insCustomClass='mt-4 mb-4' slotProps={SlotProps.Mobile_Below_Match_Carousel} />
                </div>
            }
            <div className="md:bg-white md:px-8 md:pt-8 md:pb-4 rounded-2xl md:border border-[#E3E3E4] md:mx-0 mx-[6px]">
                {filteredMatches.length > 0 ? (
                    <div className="md:grid grid-cols-2 gap-6 md:pt-2 md:mb-4">
                        {filteredMatches.map((match) => (
                            <CGLink
                                to={match?.news ? getNewsDetailsLinkPath(match?.news?.slug, match?.news?.id, 'fantasy') : null}
                                key={match?.match_id}
                            >
                                <div
                                    className="col-span-1 bg-[#F9FBFF] border border-[#DAE6F8] flex flex-col h-[180px] md:h-[215px] pt-4 items-center justify-between rounded-xl mb-4 md:mb-0"
                                >
                                    <div className="text-sm text-gray-500">{match?.tournament_name}</div>
                                    <div className="flex items-center justify-center w-full text-center md:gap-4 gap-2 py-3 md:py-5">
                                        <div className="flex items-center gap-2">
                                            <div className="md:text-lg text-sm font-semibold opacity-70">{match?.teama_name}</div>
                                            <img className="md:h-10 md:w-10 h-7 w-7" src={match?.teama_logo} alt={match?.teama_name} />
                                        </div>
                                        <div className="text-center text-[12px] md:leading-5 leading-3 text-[#6C6C6C] font-bold">
                                            STARTS AT <br />
                                            {/* <div className="md:text-[20px] text-lg text-black leading-6 font-extrabold">{(match?.date_start).substring(11, 16)}</div> */}
                                            <div className="md:text-[20px] text-lg text-black leading-6 font-extrabold">{convertDateToISD(match?.date_start)}</div>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <img className="md:h-10 md:w-10 h-7 w-7" src={match?.teamb_logo} alt={match?.teamb_name} />
                                            <div className="md:text-lg text-sm font-semibold opacity-70">{match?.teamb_name}</div>
                                        </div>
                                    </div>
                                    <div className="text-sm text-gray-500 pb-2">{match?.venue}</div>
                                    <div className="flex items-center bg-[#E8F1FF] h-9 md:h-12 w-full rounded-b-xl justify-center cursor-pointer">
                                        View
                                        <img src={viewIcon} alt="View Icon" className="ml-2" />
                                    </div>
                                </div>
                            </CGLink>
                        ))}
                    </div>
                ) : (
                    <div className="text-center text-gray-500 py-8 bg-white rounded-2xl">
                        No fantasy team for this date.
                    </div>
                )}
            </div>
        </div>
    );
};

export default FantasyV2;