import React from 'react'
import { ShimmerEffect } from '../../../common/ShimmerEffect'
import { getAssetUrl } from '../../../../helpers/general'
import { useNavigate, useParams } from 'react-router-dom'

const PlayerDetailsBannerImg = getAssetUrl('player-details-banner.png');
const PlayerDetailsBannerMobileImg = getAssetUrl('player-details-banner-mobile.png');
const CricketBatIcon = getAssetUrl('cricket-bat.svg');
const dummyPlayerImage = 'https://s3-alpha-sig.figma.com/img/871b/e473/769a262f250adae9bd2ecdcbb2aed03b?Expires=1736121600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=meRIeUuVm1OQzOFbtZ-858MuANmzBKjw~e0UGDGHl81Tg3KI6Ihnhl6qKPxOD8afcAas-njt7i9PFUYKY0j3gemJPWgZBVbM0IHJ~VsTuqoyA5AlylLNkMIlycspfMBkeYQ6QivDyvhMAcYQ-Ac5s08EArjNESEIQv6-kK2ICZBDGHRG0klrSIVwfgMItb18EzNCra2JMEuvLTbb4uc4Ra9PFtxpLutc0HMD3a9i2WLtLljfevaRI9LDuwEJvOvq6bWr0iDshz3efZ6TTyqn-hs5lyA~EI-mD2dI7SHwWs6bBO9VZYfy8Te4kGkZPbKi8XEL2oaot-aohw4s3~cQoQ__'
const indianFlag = 'https://images.entitysport.com/assets/uploads//2023/01/india-4.png'

export default function PlayerDetailsBanner({ playerSection }) {

    const { playerSlug } = useParams();
    const { section } = useParams();
    const navigate = useNavigate();

    return (
        <div
            className="flex items-center h-[433px] md:h-[300px] w-full relative bg-cover bg-center md:bg-cover md:bg-center md:px-[80px] lg:px-[140px] xl:px-[180px]"
            style={{
                backgroundImage: `url(${window.innerWidth >= 768 ? PlayerDetailsBannerImg : PlayerDetailsBannerMobileImg})`
            }}
        >
            <div className="md:flex items-center w-full">
                <div className='flex justify-center items-center w-full md:w-[222px]'>
                    <div className='relative h-[152px] w-[152px] md:h-[222px] md:w-[222px]'>
                        <ShimmerEffect src={dummyPlayerImage} alt='player-img' className='rounded-full h-full w-full' />

                        <div className='flex justify-center items-center absolute bottom-0 right-0 h-[40px] w-[40px] md:h-[60px] md:w-[60px] rounded-full shadow-[0px_5px_10px_0px_#00000040]' style={{ background: 'linear-gradient(180deg, #F5E2B7 0%, #CD9D52 100%)', }}>
                            <span className='text-black font-extrabold text-lg md:text-[26px]'>C</span>
                        </div>
                    </div>
                </div>
                <div className='w-full md:pl-10 mt-3 md:mt-0'>
                    <div className='text-center md:text-left'>
                        <h1 className='text-white font-bold text-2xl md:text-6xl'>Rohit Sharma</h1>
                        <div className='flex justify-center md:justify-start items-center gap-4 mt-3'>
                            <div className='flex items-center gap-3'>
                                <img src={indianFlag} alt="flag" className='w-4 h-4 md:w-5 md:h-5' />
                                <span className='text-[#ffffff99] text-base md:text-2xl font-semibold'>India</span>
                            </div>
                            <div className='text-[#ffffff99]'>•</div>
                            <div className='flex items-center gap-3'>
                                <span className='text-[#ffffff99] text-base md:text-2xl font-semibold'>Batter</span>
                                <img src={CricketBatIcon} alt="icon" className='w-4 h-4 md:w-5 md:h-5' />
                            </div>
                        </div>
                        <div className='flex justify-center md:justify-start items-center gap-4 mt-3'>
                            <div>
                                <h6 className='text-white font-bold italic'>4 <sup className='text-[#ffffff99] font-normal'>th</sup></h6>
                                <p className='text-[#ffffff99] font-medium uppercase'>T20</p>
                            </div>
                            <div>
                                <h6 className='text-white font-bold italic'>3 <sup className='text-[#ffffff99] font-normal'>rd</sup></h6>
                                <p className='text-[#ffffff99] font-medium uppercase'>ODI</p>
                            </div>
                            <div>
                                <h6 className='text-white font-bold italic'>220 <sup className='text-[#ffffff99] font-normal'>th</sup></h6>
                                <p className='text-[#ffffff99] font-medium uppercase'>TEST</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Tabs */}
                <div className='md:hidden bg-white p-2 rounded-[16px] md:mx-0 mx-[6px] mt-12'>
                    <div className='grid text-center p-[1px] items-center grid-cols-3 gap-2'>
                        <button
                            className={`text-[13px] text-black font-medium border-2 rounded-[14px] py-2 ${section === 'overview' ? 'bg-[#3A32D1] text-white border-[#3A32D1]' : 'border-[#eee]'}`}
                            onClick={() => {
                                navigate(`/players-details/${playerSlug}/overview`)
                            }}
                        >
                            Overview
                        </button>
                        <button
                            className={`text-[13px] text-black font-medium border-2 rounded-[14px] py-2 ${section === 'stats' ? 'bg-[#3A32D1] text-white border-[#3A32D1]' : 'border-[#eee]'}`}
                            onClick={() => {
                                navigate(`/players-details/${playerSlug}/stats`)
                            }}
                        >
                            Stats
                        </button>
                        <button
                            className={`text-[13px] text-black font-medium border-2 rounded-[14px] py-2 ${section === 'news' ? 'bg-[#3A32D1] text-white border-[#3A32D1]' : 'border-[#eee]'}`}
                            onClick={() => {
                                navigate(`/players-details/${playerSlug}/news`)
                            }}
                        >
                            News
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
