import React from 'react';
import { useEffect, useState } from "react";
import { Suspense } from 'react';
import usePost from "../../../hooks/usePost";
import {
  LOGIN_MODES,
  setCGullyLoginMode,
  setCGullyLoginToken,
} from "../../../helpers/authUtils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAssetUrl } from '../../../helpers/general';

const LazyGoogleLogin = React.lazy(() => import('./GoogleLoginButton'));

const appStore = getAssetUrl('appStore.png');

export const SocialLoginForm = () => {
  const cGullyLoginReq = usePost();
  const navigate = useNavigate();
  const [googleError, setGoogleError] = useState();
  const [googleAccessToken, setGoogleAccessToken] = useState("");
  const { isLoading, data, error: cGullyLoginError } = cGullyLoginReq;
  const cGullyLoginToken = data?.data;
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("action") === "test1234") {
      console.log(cGullyLoginToken.ghana1.ghana2);
    }

    if (typeof cGullyLoginToken === "string") {
      setCGullyLoginToken(cGullyLoginToken);
      setCGullyLoginMode(LOGIN_MODES.GOOGLE);
      navigate("/");
    }
  }, [cGullyLoginToken]);

  useEffect(() => {
    if (googleAccessToken && typeof googleAccessToken === "string") {
      cGullyLoginReq.makeRequest("/v1/user/socialLogin", {
        accessToken: googleAccessToken,
        type: "google",
      });
    }
  }, [googleAccessToken]);

  return (
    <>
      <div className="grid  gap-4">
        <Suspense fallback={<button className="bg-[#ECECEC] rounded-xl py-3">Loading..</button>}>
          <LazyGoogleLogin
            onSuccess={(token) => setGoogleAccessToken(token)}
            onError={(error) => setGoogleError(error)}
          />
        </Suspense>
        {/* <button className="bg-[#ECECEC] rounded-xl py-3">
          <img src={appStore} alt="apple" className="h-6 w-6 mx-auto" />
        </button> */}
      </div>
      <div className="text-center pt-1 text-red-700 sm:text-[14px] text-[12px]">
        {googleError ? "Something went wrong while login with google" : null}
        {cGullyLoginError
          ? "Something went wrong while fetching your data"
          : null}
        {isLoading ? "Fetching..." : null}
      </div>
    </>
  );
};