import React, { useState } from 'react'
import { ShimmerEffect } from '../../common/ShimmerEffect'
import { FaChevronDown } from "react-icons/fa";
import PlayerDetailsTable from './common/PlayerDetailsTable';
import PopularPlayers from './common/PopularPlayers';
import MoreStories from '../../common/MoreStories';

const indianFlag = 'https://images.entitysport.com/assets/uploads//2023/01/india-4.png'

export default function PlayerOverview({ data, playerSection }) {

    const [isAboutExpanded, setIsAboutExpanded] = useState(false);
    const [matchTabs, setMatchTabs] = useState('ALL');
    const recentMatches = data.recentMatches.matches;
    const battingPerformance = data.battingPerformance.data;
    const bowlingPerformance = data.bowlingPerformance.data;

    const recentMatchesColumns = [
        { title: 'Match', key: 'match' },
        { title: 'Bat', key: 'bat' },
        { title: 'Date', key: 'date' },
        { title: 'Venue', key: 'venue' },
        { title: 'Format', key: 'format' },
    ]

    const battingPerformanceColumns = [
        { title: 'Format', key: 'Format' },
        { title: 'M', key: 'M' },
        { title: 'Inn', key: 'Inn' },
        { title: 'No', key: 'No' },
        { title: 'R', key: 'R' },
        { title: 'H.S', key: 'H.S' },
        { title: 'Avg', key: 'Avg' },
        { title: 'BF', key: 'BF' },
        { title: 'Sr', key: 'Sr' },
        { title: '100s', key: '100s' },
        { title: '50s', key: '50s' },
    ]

    const bowlingPerformanceColumns = [
        { title: 'Format', key: 'Format' },
        { title: 'M', key: 'M' },
        { title: 'Inn', key: 'Inn' },
        { title: 'Overs', key: 'Overs' },
        { title: 'R', key: 'R' },
        { title: 'Wkts', key: 'Wkts' },
        { title: 'BBI', key: 'BBI' },
        { title: 'Avg', key: 'Avg' },
        { title: 'ECN', key: 'ECN' },
        { title: 'Sr', key: 'Sr' },
        { title: '4W', key: '4W' },
        { title: '5W', key: '5W' },
    ]

    return (
        <>
            {playerSection === 'overview' ? (
                <>
                    <div className='bg-white py-5 px-4 md:p-5 rounded-[28px] md:mx-0 mx-[6px] mt-5 border border-[#E3E3E4]'>
                        <div className='md:min-h-[18px] md:min-w-[180px] min-w-[50px] text-lg md:text-[28px] leading-[28px] text-[#000000] font-bold flex items-center mb-8 mt-2'>
                            <p>Player Info</p>
                            <span className='custom-border-right ml-6'></span>
                        </div>

                        <div className='md:flex gap-3 my-0 md:my-3'>
                            <div className='wfull md:w-1/2 bg-[#F9FBFF] rounded-2xl p-3 my-3 md:my-0'>
                                <p className='text-[#646464] text-xs md:text-sm font-semibold mb-2'>Full Name</p>
                                <h5 className='text-black text-sm md:text-xl font-medium md:font-semibold'>{data.playerInfo.fullName}</h5>
                            </div>
                            <div className='wfull md:w-1/2 bg-[#F9FBFF] rounded-2xl p-3 my-3 md:my-0'>
                                <p className='text-[#646464] text-xs md:text-sm font-semibold mb-2'>DOB</p>
                                <h5 className='text-black text-sm md:text-xl font-medium md:font-semibold'>{data.playerInfo.dob}</h5>
                            </div>
                        </div>
                        <div className='md:flex gap-3 my-0 md:my-3'>
                            <div className='wfull md:w-1/4 bg-[#F9FBFF] rounded-2xl p-3 my-3 md:my-0'>
                                <p className='text-[#646464] text-xs md:text-sm font-semibold mb-2'>Age</p>
                                <h5 className='text-black text-sm md:text-xl font-medium md:font-semibold'>{data.playerInfo.age}</h5>
                            </div>
                            <div className='wfull md:w-1/4 bg-[#F9FBFF] rounded-2xl p-3 my-3 md:my-0'>
                                <p className='text-[#646464] text-xs md:text-sm font-semibold mb-2'>Batting Style</p>
                                <h5 className='text-black text-sm md:text-xl font-medium md:font-semibold'>{data.playerInfo.battingStyle}</h5>
                            </div>
                            <div className='wfull md:w-1/4 bg-[#F9FBFF] rounded-2xl p-3 my-3 md:my-0'>
                                <p className='text-[#646464] text-xs md:text-sm font-semibold mb-2'>Bowling Style</p>
                                <h5 className='text-black text-sm md:text-xl font-medium md:font-semibold'>{data.playerInfo.bowlingStyle}</h5>
                            </div>
                            <div className='wfull md:w-1/4 bg-[#F9FBFF] rounded-2xl p-3 my-3 md:my-0'>
                                <p className='text-[#646464] text-xs md:text-sm font-semibold mb-2'>Batting Style</p>
                                <h5 className='text-black text-sm md:text-xl font-medium md:font-semibold'>{data.playerInfo.battingRole}</h5>
                            </div>
                        </div>

                        <div className='flex flex-wrap items-center justify-center md:justify-normal border border-[#DAE6F8] rounded-2xl py-3 px-5 md:py-5 md:px-6 my-6 gap-3 md:gap-0'>
                            <div className='w-full md:w-1/6 text-center md:text-left'>
                                <p className='text-black text-base md:text-2xl font-bold md:mb-0'>Teams</p>
                            </div>
                            {data.playerInfo.teams.map((team, index) => (
                                <div key={index} className='flex items-center justify-center md:w-1/6 text-center md:text-left gap-3'>
                                    <ShimmerEffect src={indianFlag} alt='team-flag' className='rounded-full h-5 w-5 md:h-[44px] md:w-[44px]' />
                                    <p className='text-black text-base md:text-2xl font-semibold'>{team}</p>
                                </div>
                            ))}
                        </div>

                        <div>
                            <h4 className='text-black text-base md:text-2xl font-bold mb-1'>About</h4>
                            <p className='text-xs md:text-xl font-normal text-[#6C6C6C]'>
                                {isAboutExpanded
                                    ? data.playerInfo.about
                                    : `${data.playerInfo.about.slice(0, 150)}`}
                                <span
                                    className="text-[#3A32D1] font-medium cursor-pointer italic hover:underline ml-1"
                                    onClick={() => setIsAboutExpanded(!isAboutExpanded)}
                                >
                                    {isAboutExpanded ? 'Read Less' : 'Read More...'}
                                </span>
                            </p>
                        </div>
                    </div>

                    <div className='bg-white py-5 px-4 md:p-5 rounded-[28px] md:mx-0 mx-[6px] mt-5 border border-[#E3E3E4]'>
                        <div className='md:min-h-[18px] md:min-w-[180px] min-w-[50px] text-lg md:text-[28px] leading-[28px] text-[#000000] font-bold md:flex items-center mb-8 mt-2'>
                            <div className='flex items-center w-full'>
                                <p>Popular <span className='font-normal'>Players</span></p>
                                <span className='custom-border-right ml-6'></span>
                            </div>
                            <div className="relative w-full md:w-[170px] mt-3 md:mt-0">
                                <select
                                    name="player-country"
                                    id="player-country"
                                    className="text-black text-base font-medium bg-[#EDECFD] border border-[#A19DF1] rounded-lg w-full h-[40px] pl-3 pr-10 appearance-none"
                                >
                                    <option value="all">India</option>
                                </select>
                                <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                                    <span className="text-[#000] text-[10px]"><FaChevronDown /></span>
                                </div>
                            </div>
                        </div>

                        {/* TODO player cards  */}
                        <PopularPlayers data={data.popularPlayers} />
                    </div>
                </>
            ) : null}

            <div className='bg-white py-5 px-4 md:p-5 rounded-[28px] md:mx-0 mx-[6px] mt-5 border border-[#E3E3E4]'>
                <div className='md:min-h-[18px] md:min-w-[180px] min-w-[50px] text-lg md:text-[28px] leading-[28px] text-[#000000] font-bold flex items-center mb-8 mt-2'>
                    <p>Recent From <span className='font-normal'>(Last 5 Match)</span></p>
                    <span className='custom-border-right ml-6'></span>
                </div>

                <div className='flex overflow-auto hideScrollbar gap-3'>
                    {data.recentMatches.filterOptions.map((option, index) => (
                        <button
                            key={index}
                            className={`min-w-auto md:min-w-[130px] text-black font-medium md:font-bold text-xs md:text-base border-2 rounded-[14px] py-3 whitespace-nowrap px-3 ${matchTabs === option ? 'bg-[#3A32D1] text-white border-[#3A32D1]' : 'border-[#eee]'}`}
                            onClick={() => {
                                setMatchTabs(option)
                            }}
                        >
                            {option}
                        </button>
                    ))}
                </div>

                <div className='mt-7'>
                    <PlayerDetailsTable data={recentMatches} columns={recentMatchesColumns} />
                </div>
            </div>

            <div className='bg-white py-5 px-4 md:p-5 rounded-[28px] md:mx-0 mx-[6px] mt-5 border border-[#E3E3E4]'>
                <div className='md:min-h-[18px] md:min-w-[180px] min-w-[50px] text-lg md:text-[28px] leading-[28px] text-[#000000] font-bold flex items-center mb-8 mt-2'>
                    <p>Batting Performance</p>
                    <span className='custom-border-right ml-6'></span>
                </div>

                <div className='mt-7'>
                    <PlayerDetailsTable data={battingPerformance} columns={battingPerformanceColumns} />
                </div>
            </div>

            <div className='bg-white py-5 px-4 md:p-5 rounded-[28px] md:mx-0 mx-[6px] mt-5 border border-[#E3E3E4]'>
                <div className='md:min-h-[18px] md:min-w-[180px] min-w-[50px] text-lg md:text-[28px] leading-[28px] text-[#000000] font-bold flex items-center mb-8 mt-2'>
                    <p>Bowling Performance</p>
                    <span className='custom-border-right ml-6'></span>
                </div>

                <div className='mt-7'>
                    <PlayerDetailsTable data={bowlingPerformance} columns={bowlingPerformanceColumns} />
                </div>
            </div>

            {playerSection === 'overview' ? (
            <div className='mt-5'>
                {/* newsType and currentNewsID should be dynamic */}
                <MoreStories newsType='news' currentNewsID='2413' />
            </div>
            ) : null}
        </>
    )
}
