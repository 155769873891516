import { IoChevronUpOutline } from "react-icons/io5";
import { MdChevronRight } from "react-icons/md";
import { useState } from "react";
import { TableContentsData } from "./constants";

export const TableOfContents = ({ newsObj }) => {
  const [isOpen, setIsOPen] = useState(false);

  const toggleTableOfContents = () => {
    setIsOPen(!isOpen);
  };

  const onContentClick = (id) => {
    scrollSmooth(id, -170);
  };

  const customBlocks = newsObj?.info?.customBlocks;

  const {
    matchInfo,
    teams,
    injuryNews,
    teamForm,
    pitch,
    c_vc,
    weather,
    avoidablePlayers,
    fantasyTeams
  } = newsObj?.match || {};

  const { temp, humidity, wind_speed } = weather || {};
  const isData = {
    matchInfo: Boolean(matchInfo),
    teams: teams && teams?.length > 0,
    injuryNews: injuryNews,
    teamForm: teamForm,
    pitch: pitch,
    c_vc: c_vc?.c || c_vc?.vc,
    weather: temp !== undefined && humidity !== undefined && wind_speed !== undefined,
    avoidablePlayers: avoidablePlayers,
  }

  return (
    <div className="md:mx-10 md:mt-10 mt-4 rounded-[14px] border-[#DAE6F8] border">
      <div
        className={`bg-[#E8F1FF] ${isOpen ? 'rounded-t-[14px]' : 'rounded-[14px]'} md:text-2xl text-base leading-6 p-4 font-bold`}
        onClick={toggleTableOfContents}
      >
        <div className="flex items-center gap-2 justify-between cursor-pointer">
          Table of Contents
          <IoChevronUpOutline
            className={`text-[#3A32D1] transition duration-200 ${isOpen ? '' : 'rotate-180'}`}
          />
        </div>
      </div>
      <div
        className={`content-container ${isOpen ? 'content-container-open' : 'content-container-closed'} md:grid grid-cols-1 gap-4 transition-max-height`}
      >
        {isOpen && (
          <div className={`md:p-[30px] md:grid grid-cols-1 gap-4 py-2 transition duration-300 ease-in-out ${isOpen ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}>
            <div className="flex flex-wrap justify-center">
              {customBlocks ?
                <>
                  {customBlocks
                    .sort((a, b) => a.order - b.order)
                    .map((block, index) => {
                      const matchedContent = TableContentsData.find(({ id }) => id === block.id)

                      if (matchedContent) {
                        return <>
                          {isData[matchedContent.lable] &&
                            <div
                              key={index}
                              className="flex items-center gap-2 md:px-6 md:py-4 p-2 m-1 border border-[#DAE6F8] rounded-2xl bg-white cursor-pointer"
                              onClick={() => onContentClick(matchedContent.id)}
                            >
                              {matchedContent.logo && <img src={matchedContent.logo} alt={matchedContent.heading} className="md:w-6 md:h-6 w-4 h-4" />}
                              <span className="md:text-base text-xs font-medium">{block?.heading || matchedContent.heading}</span>
                            </div>
                          }
                        </>
                      } else {
                        return <div
                          key={index}
                          className="flex items-center gap-2 md:px-6 md:py-4 p-2 m-1 border border-[#DAE6F8] rounded-2xl bg-white cursor-pointer"
                          onClick={() => onContentClick(block.id)}
                        >
                          {block.logo && <img src={block.logo} alt={block.logo} className="md:w-6 md:h-6 w-4 h-4" />}
                          <span className="md:text-base text-xs font-medium">{block.heading}</span>
                        </div>
                      }
                    })
                  }
                </>
                :
                <>
                  {
                    TableContentsData.map(({ id, logo, heading, lable }) => (
                      isData[lable] &&
                      <div
                        key={lable}
                        className="flex items-center gap-2 md:px-6 md:py-4 p-2 m-1 border border-[#DAE6F8] rounded-2xl bg-white cursor-pointer"
                        onClick={() => onContentClick(id)}
                      >
                        {logo && <img src={logo} alt={heading} className="md:w-6 md:h-6 w-4 h-4" />}
                        <span className="md:text-base text-xs font-medium">{heading}</span>
                        {/* <MdChevronRight className="ml-auto w-6 h-10 opacity-40 cursor-pointer" /> */}
                      </div>
                    ))
                  }
                </>
              }
            </div>
          </div>
        )}
      </div>
    </div>
  )
}