import React, { useEffect, useState, useContext } from 'react';
import WeeklyRecap from './common/WeeklyRecap';
import { formatDate } from '../helpers/dates';
import { useAxiosSWR } from '../../axiosInstance';
import { doScrollToTop, getNewsDetailsLinkPath, getSanitizedArray, isLiveNewsTag, resizedImageURL, srcsetImageURL } from '../helpers/general';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import SEOMeta from './pages/Home/common/SEOMeta';
import { convertSlugToTitle } from './common/MoreNews';
import AppContext, { URL_TYPES } from "src/context";
import GoogleAdUnit from './common/GoogleAdUnit';
import { doHardReloadArticle, getMobileDetect, SlotProps } from '../constants';
import LiveAnimatedRedDot from './common/LiveAnimatedRedDot';
import CGLink from './common/CGLink';
import ArticleCategoryIntroduction from './ArticleCategoryIntroduction';

export const NewsContainer = ({ newsData, title, hasNextPage, handleSeeMore, isLoading, hasError, pageNum }) => {
  const displayTitle = decodeURI(title || "");
  if (getSanitizedArray(newsData).length === 0) {
    return (
      <div className='p-4 bg-[#FFFFFF] border rounded-2xl md:mx-0 mx-[6px] m-4'>
        <div className='md:min-h-[18px] md:min-w-[180px] md:p-4 min-w-[50px] text-[24px] leading-[28px] text-[#000000] font-bold flex items-center'>
          <span className="custom-border-left mr-3 md:mr-6"></span>
          <h1 className='text-lg md:text-2xl'>{displayTitle}</h1>
          <span className="custom-border-right ml-3 md:ml-6"></span>
        </div>
        <div className='pt-2'>
          <div className='text-gray-600 md:text-lg text-sm text-center'>
            No news data available.
          </div>
        </div>
      </div>
    );
  }
  if (pageNum === 1 && hasError) {
    return (<div className='p-4'>
    </div>)
  }
  if (pageNum === 1 && isLoading) {
    return (<div className='p-4'>

    </div>)
  }

  const firstNews = newsData[0];
  const displayedNews = newsData.slice(1);
  return (
    <div className='md:mx-0 mx-[6px] bg-white border md:rounded-2xl rounded-xl'>
      {displayTitle ?
        <div className='md:min-h-[18px] md:min-w-[180px] px-5 md:py-8 py-4 min-w-[50px] text-[24px] leading-[28px] text-[#000000] font-bold flex items-center'>
          <span className="custom-border-left mr-3 md:mr-6"></span>
          <h1 className='text-lg md:text-2xl'>{displayTitle}</h1>
          <span className="custom-border-right ml-3 md:ml-6"></span>
        </div> : null
      }
      <div className={`md:px-5 px-3 pb-4 ${displayTitle ? '' : 'pt-5'}`}>
        <CGLink
          doHardRedirect={doHardReloadArticle}
          to={getNewsDetailsLinkPath(firstNews?.slug, firstNews?.id, firstNews?.type)}
          onClick={doScrollToTop}
        >
          <div>
            <img src={resizedImageURL(firstNews.image, "414x207")} srcSet={srcsetImageURL(firstNews.image, "816x408", "816x408")} alt="news" className={`w-full md:h-[408px] h-[207px] object-cover rounded-xl`} />
          </div>
          <div>
            <p className={`min-h-6 mt-3 news-title md:leading-[22px] leading-[16px] font-semibold md:text-lg text-[13px]`}>
              {firstNews.title}
            </p>
            <p className={`flex items-center md:text-sm text-[10px] md:mt-3 mt-2 md:leading-[16px] leading-3 md:font-medium text-[#787878]`}>
              {isLiveNewsTag(firstNews?.isLive) && <LiveAnimatedRedDot className='me-2' />}
              {formatDate(firstNews.date)}
            </p>
          </div>
        </CGLink>
      </div>
      <div className='grid grid-cols-2 items-stretch md:px-1'>
        {displayedNews.map((news) => (
          <div key={news.id} className="md:p-4 p-3">
            <CGLink
              doHardRedirect={doHardReloadArticle}
              to={getNewsDetailsLinkPath(news?.slug, news?.id, news?.type)}
              onClick={doScrollToTop}
            >
              <div>
                <img src={resizedImageURL(news.image, "220x110")} srcSet={srcsetImageURL(news.image, "414x207", "414x207")} alt="news image" className='md:h-[207px] h-[110px] w-full rounded-xl object-cover' />
              </div>
              <div>
                <p className={`mt-2 news-title min-h-7 md:min-h-12 md:text-base text-[13px] font-semibold leading-[14px] md:leading-[22px]`}>
                  {news.title}
                </p>
                <p className={`flex items-center mt-2 md:text-sm text-[10px] leading-[12px] md:leading-[16px] md:font-medium text-[#787878]`}>
                  {isLiveNewsTag(news?.isLive) && <LiveAnimatedRedDot className='me-2' />}
                  {formatDate(news.date)}
                </p>
              </div>
            </CGLink>
          </div>
        ))}
      </div>
      {isLoading &&
        <div></div>
      }
      <div className='flex items-end justify-center h-2 w-full bg-gradient-to-t from-[#e2e2e2ab] to-transparent'>
      </div>
      {hasNextPage &&
        <div className='text-[#3A32D1] text-center border-t py-3 md:text-sm text-[11px] font-semibold cursor-pointer' onClick={handleSeeMore}>
          <div>
            See More
          </div>
        </div>
      }
    </div>
  );
};

const NewsV2 = () => {
  const [newsType, setNewsType] = useState(
    window.location.pathname.includes("cricket-top-story") || window.location.pathname.includes("players-details") ? 'TopStories' : 'CricketNews'
  );
  const { pathname: pathName } = useLocation();
  const appContext = useContext(AppContext);
  useEffect(() => {
    const urlInfoObj = {
      type: URL_TYPES.NEWS_HOME,
      path: pathName,
      reqState: null,
    };
    appContext.updateUrlInfo(urlInfoObj);
  }, [pathName]);

  const location = useLocation();
  const tag = location.pathname.includes("tag");
  const shouldHideNewsTabs = tag;
  const keyword = location.pathname.split('/')[2];
  const tagQueryParam = tag ? `&tag=${keyword}` : '';
  const [cricketNewsPage, setCricketNewsPage] = useState(1);
  const [topStoriesNewsPage, setTopStoriesNewsPage] = useState(1);
  const { data: topStoriesData, isLoading: isTopStoriesLoading, error: hasTopStoriesError } = useAxiosSWR(shouldHideNewsTabs ? null : `/v1/news/home/top_story?per_page=7&page_no=${topStoriesNewsPage}`);
  const { data: cricketnewsData, isLoading, error } = useAxiosSWR(`/v1/news/home/news?per_page=7&page_no=${cricketNewsPage}${tagQueryParam}`);
  const navigate = useNavigate();
  const [cricketNewsData, setCricketNewsData] = useState([]);
  const [topStoriesNewsData, setTopStoriesNewsData] = useState([]);
  const isPlayerDetailsPage = location.pathname.includes("players-details");
  const [searchParams, setSearchParams] = useSearchParams();
  const showArticleCategoryIntro = searchParams.get('showArticleCategoryIntro') === "true";

  useEffect(() => {
    const data = getSanitizedArray(topStoriesData?.data);
    data.length > 0 && setTopStoriesNewsData((prevData) => [...prevData, ...data]);
  }, [topStoriesData]);

  useEffect(() => {
    const data = getSanitizedArray(cricketnewsData?.data);
    data.length > 0 && setCricketNewsData((prevData) => [...prevData, ...data]);
  }, [cricketnewsData]);

  const hasTopstoriesNextPage = topStoriesData?.total_pages > topStoriesNewsPage;
  const hasCricketnewsNextPage = cricketnewsData?.total_pages > cricketNewsPage;

  const handleSeeMore = () => {
    if (newsType === 'TopStories' && hasTopstoriesNextPage) {
      setTopStoriesNewsPage((prevPage) => prevPage + 1);
    } else if (newsType === 'CricketNews' && hasCricketnewsNextPage) {
      setCricketNewsPage((prevPage) => prevPage + 1);
    }
  };

  const md = getMobileDetect();
  const isMobile = Boolean(md.mobile());

  return (
    <div className='mt-4'>
      <SEOMeta slug="news" />
      {isMobile &&
        <div className="rounded-xl -x-auto hideScrollbar flex items-center justify-center">
          <GoogleAdUnit insCustomClass='mt-4 mb-4' slotProps={SlotProps.Mobile_Below_Match_Carousel} />
        </div>
      }
      {shouldHideNewsTabs === true || isPlayerDetailsPage ? "" :
        <div className='bg-white p-3 rounded-xl md:mx-0 mx-[6px]'>
          <div className='grid text-center p-[1px] items-center border rounded-lg grid-cols-2'>
            <button
              className={`${newsType === 'CricketNews' ? 'bg-[#3A32D1] rounded-lg py-1 text-white' : ''}`}
              onClick={() => {
                setNewsType('CricketNews')
                navigate("/cricket-news")
              }}
            >
              Cricket News
            </button>
            <button
              className={`${newsType === 'TopStories' ? 'bg-[#3A32D1] rounded-lg py-1 text-white' : ''}`}
              onClick={() => {
                setNewsType('TopStories')
                navigate("/cricket-top-story");
              }}
            >
              Top Stories
            </button>
          </div>
        </div>}
      <div className='mt-4 md:mt-8'>
        {newsType === 'TopStories' && (
          <>
            {showArticleCategoryIntro ?
              <ArticleCategoryIntroduction category_slug='cricket-top-story' /> : null
            }
            <NewsContainer
              firstNewsHeight={408}
              newsData={topStoriesNewsData}
              hasNextPage={hasTopstoriesNextPage}
              title={showArticleCategoryIntro ? '' : 'Top Stories'}
              handleSeeMore={handleSeeMore}
              isLoading={isTopStoriesLoading}
              hasError={hasTopStoriesError}
              pageNum={topStoriesNewsPage}
            />
          </>
        )}
        {
          newsType === 'CricketNews' && (
            <>
              {showArticleCategoryIntro ?
                <ArticleCategoryIntroduction category_slug='cricket-news' /> : null
              }
              <NewsContainer
                firstNewsHeight={408}
                newsData={cricketNewsData}
                hasNextPage={hasCricketnewsNextPage}
                title={showArticleCategoryIntro ? '' : shouldHideNewsTabs ? convertSlugToTitle(keyword) : 'Cricket News'}
                handleSeeMore={handleSeeMore}
                isLoading={isLoading}
                hasError={error}
                pageNum={cricketNewsPage}
              />
            </>
          )
        }
      </div>
      <div className='mt-4 md:mt-8 md:mx-0 mx-[6px]'>
        <WeeklyRecap />
      </div>
    </div>
  );
};

export default NewsV2;
