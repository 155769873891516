import React, { useEffect } from 'react'

export default function CustomBlock({ data }) {
    const htmlContent = data?.content + "";
    useEffect(() => {
        let twitterScript = null;
        let instaScript = null;
        const scriptId = Math.random().toString(36).replace(".", "");

        if (htmlContent && htmlContent.includes('twitter')) {
            twitterScript = document.createElement('script');
            twitterScript.id = 'twitter_' + scriptId;

            twitterScript.charset = 'utf-8';
            twitterScript.src = `https://platform.twitter.com/widgets.js?version=${scriptId}`;
            twitterScript.async = true;

            document.body.appendChild(twitterScript);
        }
        if (htmlContent && htmlContent.includes('www.instagram')) {
            instaScript = document.createElement('script');
            instaScript.src = `//www.instagram.com/embed.js?version=${scriptId}_${data.id}`;
            instaScript.async = true;

            document.body.appendChild(instaScript);
        }
        return () => {
            twitterScript && document.body.removeChild(twitterScript);
            instaScript && document.body.removeChild(instaScript);
        };
    }, [htmlContent]);

    return (
        <div id={data.id} className="newsContentDiv font-[400] my-4 md:my-7 rounded-[14px] border-[#DAE6F8] border bg-white ">
            <div className="bg-[#E8F1FF] rounded-t-[14px] md:text-2xl text-base leading-6 p-4 font-bold">
                <div className="flex items-center gap-2 md:justify-start justify-center">
                    {data.logo && <img src={data.logo} alt={data.heading} className="md:w-5 md:h-6 w-4 h-4" />}
                    <h2 className='text-base md:text-2xl'>{data.heading}</h2>
                </div>
            </div>
            <div
                className='md:text-[16px] text-[12px] p-4 border-t border-[#DAE6F8] leading-4 md:leading-7'
                dangerouslySetInnerHTML={{ __html: data.content }}
            />
        </div>
    )
}
