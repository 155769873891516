import React, { useState } from 'react'
import PlayerDetailsBanner from './common/PlayerDetailsBanner'
import PlayerOverview from './PlayerOverview'
import PlayerNews from './PlayerNews'
import NewsV2 from '../../NewsV2'
import { useNavigate, useParams } from 'react-router-dom'

const overViewData = {
    "playerInfo": {
        "fullName": "Rohit Gurunath Sharma",
        "dob": "April 30, 1987, Bansod, Nagpur, Maharashtra",
        "age": "37y 181d",
        "battingStyle": "Right Hand Bat",
        "bowlingStyle": "Right arm Offbreak",
        "battingRole": "Top Order Batter",
        "teams": ["IND", "IND", "IND", "IND", "IND"],
        "about": "Lorem Ipsum is a dummy text. The Indian Premier League (IPL) is the most intensely contested T20 league in the world as it features the best cricketers from all over the globe.Lorem Ipsum is a dummy text. The Indian Premier League (IPL) is the most intensely contested T20 league in the world as it features the best cricketers from all over the globe.Lorem Ipsum is a dummy text. The Indian Premier League (IPL) is the most intensely contested T20 league in the world as it features the best cricketers from all over the globe."
    },
    "popularPlayers": [
        { "name": "Virat Kohli", "role": "BAT", "team": "India" },
        { "name": "Virat Kohli", "role": "BAT", "team": "India" },
        { "name": "Virat Kohli", "role": "BAT", "team": "India" },
        { "name": "Virat Kohli", "role": "BAT", "team": "India" },
        { "name": "Virat Kohli", "role": "BAT", "team": "India" },
        { "name": "Virat Kohli", "role": "BAT", "team": "India" },
        { "name": "Virat Kohli", "role": "BAT", "team": "India" },
        { "name": "Virat Kohli", "role": "BAT", "team": "India" },
        { "name": "Virat Kohli", "role": "BAT", "team": "India" },
        { "name": "Virat Kohli", "role": "BAT", "team": "India" },
        { "name": "Virat Kohli", "role": "BAT", "team": "India" },
        { "name": "Virat Kohli", "role": "BAT", "team": "India" },
        { "name": "Virat Kohli", "role": "BAT", "team": "India" },
        { "name": "Virat Kohli", "role": "BAT", "team": "India" },
        { "name": "Virat Kohli", "role": "BAT", "team": "India" },
    ],
    "recentMatches": {
        "filterOptions": ["ALL", "T20", "ODI", "TEST", "T10", "100-BALL"],
        "matches": [
            {
                "match": "India Vs NZ",
                "bat": "0 & 8",
                "date": "24 Oct 2024",
                "venue": "Pune",
                "format": "Test"
            },
            {
                "match": "India Vs NZ",
                "bat": "0 & 8",
                "date": "24 Oct 2024",
                "venue": "Pune",
                "format": "Test"
            },
            {
                "match": "India Vs NZ",
                "bat": "0 & 8",
                "date": "24 Oct 2024",
                "venue": "Pune",
                "format": "Test"
            },
            {
                "match": "India Vs NZ",
                "bat": "0 & 8",
                "date": "24 Oct 2024",
                "venue": "Pune",
                "format": "Test"
            },
            {
                "match": "India Vs NZ",
                "bat": "0 & 8",
                "date": "24 Oct 2024",
                "venue": "Pune",
                "format": "Test"
            }
        ]
    },
    "battingPerformance": {
        "columns": ["Format", "M", "Inn", "No", "R", "H.S", "Avg", "BF", "Sr", "100s", "50s"],
        "data": [
            {
                "Format": "TEST",
                "M": 60,
                "Inn": 103,
                "No": 10,
                "R": 10310,
                "H.S": 212,
                "Avg": 44.60,
                "BF": 6942,
                "Sr": 57.23,
                "100s": 12,
                "50s": 13,
            },
            {
                "Format": "ODI",
                "M": 60,
                "Inn": 103,
                "No": 10,
                "R": 10310,
                "H.S": 212,
                "Avg": 44.60,
                "BF": 6942,
                "Sr": 57.23,
                "100s": 12,
                "50s": 13,
            },
            {
                "Format": "T20I",
                "M": 60,
                "Inn": 103,
                "No": 10,
                "R": 10310,
                "H.S": 212,
                "Avg": 44.60,
                "BF": 6942,
                "Sr": 57.23,
                "100s": 12,
                "50s": 13,
            },
            {
                "Format": "T20",
                "M": 60,
                "Inn": 103,
                "No": 10,
                "R": 10310,
                "H.S": 212,
                "Avg": 44.60,
                "BF": 6942,
                "Sr": 57.23,
                "100s": 12,
                "50s": 13,
            },
            {
                "Format": "FC",
                "M": 60,
                "Inn": 103,
                "No": 10,
                "R": 10310,
                "H.S": 212,
                "Avg": 44.60,
                "BF": 6942,
                "Sr": 57.23,
                "100s": 12,
                "50s": 13,
            },
            {
                "Format": "LIST A",
                "M": 60,
                "Inn": 103,
                "No": 10,
                "R": 10310,
                "H.S": 212,
                "Avg": 44.60,
                "BF": 6942,
                "Sr": 57.23,
                "100s": 12,
                "50s": 13,
            },
            {
                "Format": "T10",
                "M": "-",
                "Inn": "-",
                "No": "-",
                "R": "-",
                "H.S": "-",
                "Avg": "-",
                "BF": "-",
                "Sr": "-",
                "100s": "-",
                "50s": "-",
            },
        ],
    },
    "bowlingPerformance": {
        "columns": ["Format", "M", "Inn", "Overs", "R", "Wkts", "BBI", "Avg", "ECN", "Sr", "4W", "5W"],
        "data": [
            {
                "Format": "TEST",
                "M": 60,
                "Inn": 103,
                "Overs": 63.50,
                "R": 10310,
                "Wkts": 10,
                "BBI": "1/26",
                "Avg": 44.60,
                "ECN": 3.50,
                "Sr": 57,
                "4W": 12,
                "5W": 13,
            },
            {
                "Format": "ODI",
                "M": 60,
                "Inn": 103,
                "Overs": 63.50,
                "R": 10310,
                "Wkts": 10,
                "BBI": "1/26",
                "Avg": 44.60,
                "ECN": 3.50,
                "Sr": 57,
                "4W": 12,
                "5W": 13,
            },
            {
                "Format": "T20I",
                "M": 60,
                "Inn": 103,
                "Overs": 63.50,
                "R": 10310,
                "Wkts": 10,
                "BBI": "1/26",
                "Avg": 44.60,
                "ECN": 3.50,
                "Sr": 57,
                "4W": 12,
                "5W": 13,
            },
            {
                "Format": "T20",
                "M": 60,
                "Inn": 103,
                "Overs": 63.50,
                "R": 10310,
                "Wkts": 10,
                "BBI": "1/26",
                "Avg": 44.60,
                "ECN": 3.50,
                "Sr": 57,
                "4W": 12,
                "5W": 13,
            },
            {
                "Format": "FC",
                "M": 60,
                "Inn": 103,
                "Overs": 63.50,
                "R": 10310,
                "Wkts": 10,
                "BBI": "1/26",
                "Avg": 44.60,
                "ECN": 3.50,
                "Sr": 57,
                "4W": 12,
                "5W": 13,
            },
            {
                "Format": "LIST A",
                "M": 60,
                "Inn": 103,
                "Overs": 63.50,
                "R": 10310,
                "Wkts": 10,
                "BBI": "1/26",
                "Avg": 44.60,
                "ECN": 3.50,
                "Sr": 57,
                "4W": 12,
                "5W": 13,
            },
            {
                "Format": "T10",
                "M": "-",
                "Inn": "-",
                "Overs": "-",
                "R": "-",
                "Wkts": "-",
                "BBI": "-",
                "Avg": "-",
                "ECN": "-",
                "Sr": "-",
                "4W": "-",
                "5W": "-",
            },
        ],
    }
}

export default function PlayersProfile() {

    const {playerSlug} = useParams();
    const playerId = playerSlug.split('-').pop();
    const { section } = useParams();
    const navigate = useNavigate();

    const renderSection = () => {
        if (section === 'overview') {
            return <PlayerOverview data={overViewData} playerSection="overview" />;
        } else if (section === 'stats') {
            return <PlayerOverview data={overViewData} playerSection="stats" />;
        } else if (section === 'news') {
            return <NewsV2 />;
        } else {
            return <div>Section not found</div>;
        }
    };

    return (
        <>
            {/* <PlayerDetailsBanner playerSection={section} /> */}

            <div className='max-w-[1320px] xl:mx-auto'>
                {/* tabs */}
                <div className='hidden md:block bg-white p-5 rounded-[28px] md:mx-0 mx-[6px] mt-5 border border-[#E3E3E4]'>
                    <div className='grid text-center p-[1px] items-center grid-cols-3 gap-4'>
                        <button
                            className={`text-black font-bold text-base border-2 rounded-[14px] py-3 ${section === 'overview' ? 'bg-[#3A32D1] text-white border-[#3A32D1]' : 'border-[#eee]'}`}
                            onClick={() => {
                                navigate(`/players-details/${playerSlug}/overview`)
                            }}
                        >
                            Overview
                        </button>
                        <button
                            className={`text-black font-bold text-base border-2 rounded-[14px] py-3 ${section === 'stats' ? 'bg-[#3A32D1] text-white border-[#3A32D1]' : 'border-[#eee]'}`}
                            onClick={() => {
                                navigate(`/players-details/${playerSlug}/stats`)
                            }}
                        >
                            Stats
                        </button>
                        <button
                            className={`text-black font-bold text-base border-2 rounded-[14px] py-3 ${section === 'news' ? 'bg-[#3A32D1] text-white border-[#3A32D1]' : 'border-[#eee]'}`}
                            onClick={() => {
                                navigate(`/players-details/${playerSlug}/news`)
                            }}
                        >
                            News
                        </button>
                    </div>
                </div>

                <div className='my-5'>
                    {/* {playerSection === 'Overview' ?
                        <PlayerOverview data={overViewData} playerSection={playerSection} />
                        : playerSection === 'Stats' ?
                            <PlayerOverview data={overViewData} playerSection={playerSection} />
                            : <NewsV2 />
                    } */}
                    {renderSection()}
                </div>
            </div>

        </>
    )
}
