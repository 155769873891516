import { getAssetUrl } from "../../../../helpers/general";

const teamlogo = getAssetUrl("team.svg");
const captainlogo = getAssetUrl("player-cap.svg");
const userlogo = getAssetUrl("user-banned.svg");
const cricketlogo = getAssetUrl("cricket.svg");
const cricketlogo2 = getAssetUrl("cricket2.svg");
const injurylogo = getAssetUrl("injury.svg");
const pitchlogo = getAssetUrl("pitch.svg");
const weatherlogo = getAssetUrl("weather.svg");
const playingXI = getAssetUrl("playingXI.svg");

export const TableContentsData = [
    {
      lable: "matchInfo",
      id:"match-info",
      logo: cricketlogo,
      heading: 'Match Info'
    },
    {
      lable: "teams",
      id:"match-teams",
      logo: cricketlogo2,
      heading: 'Teams'
    },
    {
      lable: "injuryNews",
      id:"injury",
      logo: injurylogo,
      heading: 'Injury News'
    },
    {
      lable: "teamForm",
      id:"team-form",
      logo: teamlogo,
      heading: 'Team Form'
    },
    {
      lable: "pitch",
      id:"pitch-report",
      logo: pitchlogo,
      heading: 'Pitch'
    },
    {
      lable: "c_vc",
      id:"captain-vice-captain",
      logo: captainlogo,
      heading: 'C & VC'
    },
    {
      lable: "weather",
      id:"weather-report",
      logo: weatherlogo,
      heading: 'Weather Report'
    },
    {
      lable: "avoidablePlayers",
      id:"avoidable-players",
      logo: userlogo,
      heading: 'Avoidable Players'
    },
    {
      lable: "player-stats",
      id:"player-stats",
      logo: null,
      heading: 'Player Stats'
    }
];

  export const logoData = {
    matchInfo: {
      logo: cricketlogo,
      heading: 'Match Info'
    },
    teams: {
      logo: cricketlogo2,
      heading: 'Teams'
    },
    injury: {
      logo: injurylogo,
      heading: 'Injury'
    },
    pitchReport: {
      logo: pitchlogo,
      heading: 'Pitch Report'
    },
    weatherReport: {
      logo: weatherlogo,
      heading: 'Weather Report'
    },
    teamForm: {
      logo: teamlogo,
      heading: 'Team Form'
    },
    captainViceCaptain: {
      logo: captainlogo,
      heading: 'Captain & Vice Captain'
    },
    avoidablePlayers: {
      logo: userlogo,
      heading: 'Avoidable Players'
    },
    playerStats: {
      logo: null,
      heading: 'Players Stats in current series'
    }
  };
