import React, { useEffect, useRef, useState } from 'react'
import { useScrollToTopButtonPostion } from '../../hooks/useShowHideOnScroll';
import debounce from 'lodash.debounce';

const ScrollToTopButton = React.memo(() =>  {

    const [isHidden, setIsHidden] = useState(true);
    const scrollToTopBtnContainerRef = useRef(null);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsHidden(false);
        } else {
            setIsHidden(true);
        }
    };

    useEffect(() => {
        const debouncedFn = debounce(handleScroll, 200);
        window.addEventListener('scroll', debouncedFn);
        return () => {
            window.removeEventListener('scroll', debouncedFn);
        };
    }, [])

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useScrollToTopButtonPostion(scrollToTopBtnContainerRef);

    return (
        <div ref={scrollToTopBtnContainerRef} className={`${isHidden ? 'hidden' : 'block'} fixed right-2 bottom-[144px] md:bottom-6 md:right-10 z-[10001] transition duration-300`}>
            <button
                className='flex justify-center items-center bg-white h-11 w-11 rounded-full shadow text-[#3A32D1] cursor-pointer'
                onClick={scrollToTop}
            >
                <svg width="20px" height="20px" viewBox="0 0 32 32" version="1.1"><g id="arrow-up" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><path d="M27.4137878,15.4634462 C27.0262614,15.8586458 26.5137263,16.0531091 26.0011913,16.0531091 C25.4886563,16.0531091 24.9773713,15.8570775 24.5873447,15.4650144 L18.0006445,8.8579674 L18.0006445,28.0972869 C18.0006445,29.2069822 17.1049582,30.0481928 16.0567616,30.0481928 C15.0085649,30.0481928 14.000371,29.2076095 14.000371,28.0972869 L14.000371,8.8579674 L7.41429587,15.4634462 C6.63299247,16.2475723 5.36728095,16.2475723 4.58597755,15.4634462 C3.80467415,14.67932 3.80467415,13.4090356 4.58597755,12.6249095 L14.5866611,2.58809462 C15.3679645,1.80396846 16.633676,1.80396846 17.4149794,2.58809462 L27.415663,12.6249095 C28.1950912,13.4121721 28.1950912,14.67932 27.4137878,15.4634462 Z" fill="currentColor" fillRule="nonzero"></path></g></svg>
            </button>
        </div>
    )
})

export default ScrollToTopButton;